import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserProfile from "../../assets/img/UserProfile.jpg";
import "./SearchBox.css";
import { SearchCharger } from "../Charger/SearchCharger/SearchCharger";
import { UserPerfilModel } from "../../Models/User/UserPerfilModel";
import MediaData from "../../Services/MediaData/MediaDataService";
import SearchItem from "./SearchItem";

interface ISearch {
  usersSearch: any[];
  searchload: boolean;
}

const SearchBox = (props: ISearch) => {
  const [users, setUsers] = useState<UserPerfilModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const updateProfilePhotos = async () => {
      const updatedUsers = await Promise.all(props.usersSearch.map(async (u: any) => {
        // console.log("Los usuarios de la consulta: " , u, u.perfilData?.idMediaDataProfile);
        if (u.perfilData?.idMediaDataProfile) {
          try {
            const pPDta: any = await MediaData.GetById(u.perfilData?.idMediaDataProfile);
            u.profilePhoto = pPDta.data.value;
          } catch (error) {
            console.error(`Error fetching media data for user ${u.id}:`, error);
            u.profilePhoto = "";
          }
        } else {
          u.profilePhoto = "";
        }
        return u;
      }));

      setUsers(updatedUsers);
      setLoading(false);
    };

    updateProfilePhotos();
  }, [props.usersSearch]);

  return (
    <div className="col-md-5 search-box">
      <div className="conteiner_search_box col-md-12">
        {loading ? (
          <>
            <SearchCharger />
            <SearchCharger />
            <SearchCharger />
            <SearchCharger />
            <SearchCharger />
            <SearchCharger />
            <SearchCharger />
          </>
        ) : (
          users.map((u: UserPerfilModel, index) =>
            <SearchItem key={index} user={u} />
          )
        )}
      </div>
      <br />
      <br />
    </div>
  );
};

export default SearchBox;

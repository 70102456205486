import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Post from "../Services/Post/PostService";
import Auth from "../Services/Auth/AuthService";
import { PostModel } from "../Models/Post/PostModel";
import Publication from "../Modules/Publications/Publication/Publication";
import IndexPostCharger from "../Modules/Charger/IndexPostCharger/IndexPostCharger";

const PublicationPage = () => {
  const { id } = useParams();
  const [publication, setPublication] = useState<PostModel>();
  const [chargingPost, setChargingPost] = useState<boolean>(true);

  useEffect(() => {
    getPost(id!);
  }, [id]);

  const updatePost = (
    id: string,
    updates: Partial<
      Pick<PostModel, "description" | "status" | "likes" | "comments" | "liked">
    >
  ) => {
    setPublication!((prevPublication) => {
      if (!prevPublication) return prevPublication;
      return {
        ...prevPublication,
        ...updates,
      };
    });
  };

  const getPost = async (id: string) => {
    Post.GetById(id, Auth._User?.user?.id!)
      .then((e: any) => {
        if (e.data) {
          setPublication(e.data);
          setChargingPost(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  return chargingPost ? (
    <IndexPostCharger />
  ) : (
    <Publication publication={publication!} updatePost={updatePost} />
  );
};

export default PublicationPage;

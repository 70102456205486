import React, { useEffect, useState } from 'react'
import { PostModel } from '../Models/Post/PostModel'
import Auth from '../Services/Auth/AuthService'
import Post from '../Services/Post/PostService'
import History from "../Services/History/HistoryService";
import { HistoryListItemModel } from '../Models/History/HistoryListItemModel';
import { PostParams } from '../Models/Post/PostParams';
import { UserPerfilModel } from '../Models/User/UserPerfilModel';
export const useIndexData = () => {
    const [buttonsVisible, setButtonsVisible] = useState<boolean>(false)
    const [searchVisible, setSearchVisible] = useState<boolean>(false)
    const [searchInput, setSearchInput] = useState<boolean>(false)
    const [usersSearch, setUsersSearch] = useState<any[]>([])
    const [footerViewer, setFooterViewer] = useState<boolean>(true)

    const [BdPost, setBdPost] = useState<PostModel[]>([])
    const [historyList, setHistoryList] = useState<HistoryListItemModel[]>()
    const [myhistorys, setMyHistorys] = useState<HistoryListItemModel[]>()
    const [OnLoadPost, setOnLoadPost] = useState<PostParams>()

    const [chargingPost, setChargingPost] = useState<boolean>(true);
    const [chargingMyHistory, setChargingMyHistory] = useState<boolean>(true);
    const [chargingOthersHistorys, setChargingOthersHistorys] = useState<boolean>(true);

    const [indexPostSection, setIndexPostSection] = useState<number>(1);
    // const [indexPostSection, setIndexPostSection] = useState<number>(1);
    const [goToExplorerFromIndex, setGoToExplorerFromIndex] = useState<boolean>(false);


    useEffect(() => {
        if (Auth._User?.user?.id) {
            getPost(Auth._User?.user?.id, indexPostSection)
            getHistoryList(Auth._User?.user?.id)
            getHistorysById(Auth._User?.user?.id)
        }
    }, [Auth._User?.user?.id])


    const [bool, setBool] = useState<boolean>(true);
    const back = document.getElementById("app_contain");

    const handleScroll = () => {
        const scrollTop = back?.scrollTop || 0;
        const scrollHeight = back?.scrollHeight || 0;
        if (scrollTop > scrollHeight / 4 && bool && window.location.pathname == '/') {
            setBool(false);
            setChargingPost(true);
            getPost(Auth._User?.user?.id, indexPostSection + 5);
            setIndexPostSection((prevIndex) => prevIndex + 5);
        }
    };

    useEffect(() => {
        back?.addEventListener("scroll", handleScroll);

        return () => {
            back?.removeEventListener("scroll", handleScroll);
        };
    }, [bool]);


    const getPost = (id: string | undefined, section: number) => {
        Post.GetIndexPost(id!, section)
            .then((e: any) => {
                setBdPost(prevBdPost => [...prevBdPost, ...e.data]);
                if (e.data.length > 0) {
                    setBool(true);
                } else {
                    setGoToExplorerFromIndex(true)
                }
                setChargingPost(false)
            })
            .catch((err: any) => {
                console.error(err);
            })
    }

    const getHistoryList = (id: string | undefined) => {
        History.GetHistoryList(id!, 1)
            .then((e: any) => {
                setHistoryList(e.data)
                setChargingOthersHistorys(false)
            })
            .catch((err: any) => {
                console.error(err);
            })
    }

    const getHistorysById = (id: string | undefined) => {
        History.GetHistorysById(id!)
            .then((e: any) => {
                setMyHistorys(e.data)
                setChargingMyHistory(false)
            })
            .catch((err: any) => {
                console.error(err);
            })
    }

    const ConcatMyHistorys = (history: any) => {
        const historyArray = myhistorys![0].historysData!.concat(history)
        const historyUser = myhistorys!.map((D: HistoryListItemModel) => {
            D.historysData = historyArray;
            return D;
        })
        setMyHistorys(historyUser)
    }

    const setOnLoadPhoto = (OnLoadP: PostParams) => {
        setOnLoadPost(OnLoadP);
    }

    const setPhoto = (post: any) => {
        setBdPost((BdPost: any) => [post, ...BdPost]);
    }

    const setBdPost_F = (p: PostModel[]) => {
        setBdPost(p);
    }


    const setButtonsVisible_F = (b: boolean) => {
        setButtonsVisible(b)
    }

    const setHistoryList_F = (h: HistoryListItemModel[]) => {
        setHistoryList(h)
    }

    const setMyHistorys_F = (h: HistoryListItemModel[]) => {
        setMyHistorys(h)
    }

    const setFooterViewer_F = (b: boolean) => {
        setFooterViewer(b)
    }

    return {
        searchVisible,
        buttonsVisible,
        setButtonsVisible_F,
        historyList,
        setHistoryList_F,
        myhistorys,
        ConcatMyHistorys,
        setMyHistorys_F,
        setPhoto,
        setOnLoadPhoto,
        setFooterViewer_F,
        footerViewer,
        BdPost,
        OnLoadPost,
        setBdPost_F,
        setButtonsVisible,
        setSearchVisible,
        searchInput,
        setSearchInput,
        setUsersSearch,
        chargingPost,
        usersSearch,
        chargingMyHistory,
        chargingOthersHistorys,
        goToExplorerFromIndex
    }
}

import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./Interactor.css";
import RoundProfileCard from "./Elemets/RoundProfileCard/RoundProfileCard";
import PhotoMosaic from "./Elemets/PostMosaic/PostMosaic";
import { PostModel } from "../../Models/Post/PostModel";
import TendenciaCharger from "../Charger/TendenciaCharger/TendenciaCharger";
import MosaicoCharger from "../Charger/MosaicoCharger/MosaicoCharger";
import { AppContext } from "../../context/AppContext";
import TendenciasCard from "./Elemets/TendenciasCard/TendenciasCard";
import PublicationModalView from "../Modals/PublicationModalView";
const Interactor = () => {
  const [isVisiblePublicationModalView, setVisiblePublicationModalView] =
    useState<boolean>(false);
  const [indexPost, setIndexPost] = useState<number>(0);
  const [explorerViewPostLocation, setExplorerViewPostLocation] = useState<
    "Trends" | "Mosaic"
  >();

  const Context = useContext(AppContext);
  if (!Context) {
    // Manejar el caso cuando el contexto es nulo
    return null;
  }
  const {
    tendenciasCharger,
    tendencias,
    setTendencias_F,
    mosaicosCharger,
    mosaicos,
    setMosaicos_F,
    users,
  } = Context;

  const watchElement = (index: number, location: "Trends" | "Mosaic") => {
    setExplorerViewPostLocation(location);
    setIndexPost(index);
    setVisiblePublicationModalView(true);
  };

  const updateTrends = (
    id: string,
    updates: Partial<
      Pick<PostModel, "description" | "status" | "likes" | "comments" | "liked">
    >
  ) => {
    const updatedPosts = tendencias!.map((post: PostModel) =>
      post.id === id ? { ...post, ...updates } : post
    );
    setTendencias_F(updatedPosts);
  };

  const updateMosaic = (
    id: string,
    updates: Partial<
      Pick<PostModel, "description" | "status" | "likes" | "comments" | "liked">
    >
  ) => {
    const updatedPosts = mosaicos!.map((post: PostModel) =>
      post.id === id ? { ...post, ...updates } : post
    );
    setMosaicos_F(updatedPosts);
  };

  return (
    <div className="explorer-section">
      <div className="popular-content">
        <h3 style={{ marginLeft: "40px" }}>Tendencias</h3>
        <div className="popular-content-div">
          <div className="container-tendencia" id="tendencia-box">
            {tendenciasCharger && <TendenciaCharger />}
            {tendencias!.map((e: PostModel, key: number) => (
              <TendenciasCard
                key={key}
                index={key}
                publication={e}
                tendencia={key}
                watchElement={watchElement}
              />
            ))}
          </div>
        </div>
      </div>

      <hr />
      <div className="featured-users" id="users-box">
        {users!.map((e: any, key: number) => (
          <RoundProfileCard
            key={key}
            name={e.name}
            username={e.username}
            idMediaDataProfile={e.perfilData.idMediaDataProfile}
          />
        ))}
      </div>

      <hr />

      {mosaicosCharger && <MosaicoCharger />}
      <PhotoMosaic posts={mosaicos!} watchElement={watchElement} />

      {isVisiblePublicationModalView &&
        (explorerViewPostLocation == "Trends"
          ? tendencias!.length > 0 && tendencias![indexPost] !== undefined
          : mosaicos!.length > 0 && mosaicos![indexPost] !== undefined) && (
          <PublicationModalView
            setModalVisible={setVisiblePublicationModalView}
            isModalVisible={isVisiblePublicationModalView}
            index={indexPost}
            setIndex={setIndexPost}
            publications={
              explorerViewPostLocation == "Trends" ? tendencias! : mosaicos!
            }
            setPublications={
              explorerViewPostLocation == "Trends" ? updateTrends : updateMosaic
            }
          />
        )}

      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Interactor;

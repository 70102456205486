import React from 'react'

const Contacto = () => {
    return (
        <>
            <br />
            <br />
            <div className="col-md-8 config-container">
                <h3>Contacto</h3>
                <hr />
                <div className="col-md-12">
                    <h5>Estimados,</h5>

                    <p>Si desean ponerse en contacto con nosotros, estamos encantados de atender sus consultas, preguntas o solicitudes. Nuestro equipo está comprometido en brindarles un excelente servicio y responder a sus necesidades.</p>

                    <p>Pueden comunicarse con nosotros a través de los siguientes medios:</p>

                    <ul>
                        <li>Teléfono: <a href="tel:8492756806">849-275-6806</a></li>
                        <li>Correo electrónico: <a href="mailto:info@aycoro.com">info@aycoro.com</a></li>
                        <li>Sitio web: <a href="https://www.aycoro.com">www.aycoro.com</a></li>
                    </ul>

                    <p>Agradecemos su interés.</p>

                    <p>Atentamente,
                        <br />
                        Equipo de Aycoro
                    </p>
                </div>
            </div>
        </>
    )
}

export default Contacto
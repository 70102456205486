import { INotificationService } from "../../Interface/Notificacion/INotificationService";
import Http from "../Http/HttpClient";

export class NotificationService implements INotificationService {
  async Delete(id: string): Promise<any> {
    let result = await new Promise<any>((resolve, reject) => {
      Http.put(`api/Notification?id=${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }

  async GetAll(idUser: string, section: number): Promise<any> {
    let result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/Notification?IdUser=${idUser}&section=${section}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }

  async GetUnreadNotificationCount(idUser: string): Promise<any> {
    let result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/Notification/${idUser}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }

  async SetNotificationRead(idUser: string): Promise<any> {
    let result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/Notification/NotifyRead/${idUser}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
}

const Notification = new NotificationService();
export default Notification;

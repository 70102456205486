import React, { useContext, useEffect, useState } from "react";
import "./TendenciasCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faHeart } from "@fortawesome/free-solid-svg-icons";
import { PostModel } from "../../../../Models/Post/PostModel";
import { ImageModel } from "../../../../Models/Post/ImageModel";
import { AppContext } from "../../../../context/AppContext";
import MediaData from "../../../../Services/MediaData/MediaDataService";

interface ITendenciasCard {
  index: number;
  publication: PostModel | undefined;
  tendencia: number;
  watchElement: Function;
}

const TendenciasCard = ({
  index,
  publication,
  tendencia,
  watchElement,
}: ITendenciasCard) => {
  const [image, setImage] = useState<ImageModel>();

  useEffect(() => {
    handleSrc(publication!.idMediaData!);
  }, [publication!.id]);

  const handleSrc = (id: string) => {
    const image = searchImage(id);
    if (image.length > 0) {
      setImage(image[0]);
    } else {
      MediaData.GetById(id)
        .then((e: any) => {
          setImage(e.data);
          handleImageBank(e.data);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  const Context = useContext(AppContext);
  if (!Context) {
    return null;
  }
  const { searchImage, handleImageBank } = Context;
  return (
    <div
      className="popular-content-card col-md-2"
      onClick={() => watchElement(index, "Trends")}
    >
      {image ? (
        <img
          src={image?.value}
          alt={publication!.username}
          className="card-image"
        />
      ) : (
        <div className="card-image color-changing" />
      )}

      <div className="card-info">
        <h3 className="card-title">{publication!.username}</h3>

        <div className="interaction-info">
          <span className="likes">
            <FontAwesomeIcon icon={faHeart} color={"red"} />{" "}
            {publication!.likes}
          </span>
          <span className="comments">
            <FontAwesomeIcon icon={faComment} /> {publication!.comments}
          </span>
        </div>
        <b>Tendencia N.º {tendencia + 1}</b>
      </div>
    </div>
  );
};

export default TendenciasCard;

import React, { useEffect, useState } from "react";
import UserProfile from "../../assets/img/UserProfile.jpg";
import getNotifyMessageHook from "../../hooks/getNotifyMessageHook";
import { NotificationModel } from "../../Models/Notification/NotificationModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useImageBankHook } from "../../hooks/useImageBankHook";
import MediaData from "../../Services/MediaData/MediaDataService";
import { NotifyType } from "../../constants/Types";
import { useNavigate } from "react-router-dom";
import Notification from "../../Services/Notification/NotificationService";

interface INotificationItem {
  item: NotificationModel;
  setRead: Function;
  deleteNotify: Function;
}

const NotificationItem = ({ item, setRead, deleteNotify } : INotificationItem) => {
  const navigate = useNavigate();
  const { getNotify } = getNotifyMessageHook();
  const [image, setImage] = useState<string>();
  const [imageProfile, setImageProfile] = useState<string>();

  const { searchImage, handleImageBank } = useImageBankHook();

  useEffect(() => {
    const fetchImageData = async () => {
      const [mediaData, profileMediaData] = await Promise.all([
        getMedia(item.idMediaData),
        getMedia(item.idMediaDataProfile),
      ]);

      setImage(mediaData);
      setImageProfile(profileMediaData);
    };

    fetchImageData();
  }, [item]);

  const handleSrc = (id: string): string => {
    const image = searchImage(id);
    return image.length > 0 ? image[0].value! : "";
  };

  const getMedia = async (id: any) => {
    if (!id) return "";

    try {
      const imageBank = handleSrc(id);
      if (imageBank) return imageBank;

      const profilePhotoResponse = await MediaData.GetById(id);
      if (profilePhotoResponse.data) {
        handleImageBank(profilePhotoResponse.data);
        return profilePhotoResponse.data.value;
      }
    } catch (err) {
      console.error("Error fetching media data:", err);
    }

    return "";
  };

  return (
    <div
      className="dropdown-item"
      id="tittle_dropdown_1"
      onClick={() => {
        if (item.type == NotifyType.FOLLOW) {
          navigate(`/${item.username}`);
        } else {
          navigate(`/publication/${item.idItem}`);
        }
        setRead(item.id);
      }}
      style={{ width: "360px", height: "60px" }}
    >
      {!item.isRead && (
        <div
          style={{
            width: "10px",
            height: "8px",
            borderRadius: "50%",
            backgroundColor: "red",
          }}
        />
      )}
      <div style={{ width: "50px", height: "50px" }}>
        <img
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          src={imageProfile ? imageProfile : UserProfile}
          alt=""
        />
      </div>
      <div
        style={{
          width: "250px",
          wordWrap: "break-word",
          whiteSpace: "normal",
          overflowWrap: "break-word",
          marginRight: "5px",
          marginLeft: "10px",
        }}
      >
        {getNotify(item.username!, item.type!, "")}
      </div>
      <div style={{ width: "50px", height: "50px" }}>
        {item.type !== NotifyType.FOLLOW && (
          <img style={{ width: "50px", height: "50px" }} src={image} alt="" />
        )}
      </div>
      <div
        className="icon-drop"
        style={{ marginLeft: "5px", marginTop: "5px" }}
        onClick={() => deleteNotify(item.id)}
      >
        <FontAwesomeIcon className="icon-dropdown" icon={faClose} />
      </div>
    </div>
  );
};

export default NotificationItem;

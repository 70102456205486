import React, { useState } from 'react'
import { ImageModel } from '../Models/Post/ImageModel'

export const useImageBankHook = () => {
    const [imagesBanck, setImagesBanck] = useState<ImageModel[]>([])

    const searchImage = (id: string) => {
        const element = imagesBanck.filter(image => image.id === id);
        return element;
    }

    const handleImageBank = (image: ImageModel) => {
        setImagesBanck(prevImage => [...prevImage, ...[image]]);
    }

    return {
        searchImage,
        handleImageBank
    }
}

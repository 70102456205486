import Config from "../Modules/Config/Config";
import { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import Auth from "../Services/Auth/AuthService";

const SettingsPage = () => {
  const { searchVisible, isOnline } = useContext(AppContext) || {};

  useEffect(() => {
    if (Auth._User?.user?.id && Auth._User?.user?.username) {
      isOnline!(Auth._User.user.id, Auth._User.user.username);
    }
  }, [isOnline]);

  if (searchVisible) return null;

  return <Config />;
};

export default SettingsPage;

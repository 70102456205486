import './PhotoMosaic.css';
import photoSrc from "../../../../assets/img/UserProfile.jpg";
import { PostModel } from '../../../../Models/Post/PostModel';
import User from '../../../../Services/User/UserService';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ImageModel } from '../../../../Models/Post/ImageModel';
import { AppContext } from '../../../../context/AppContext';
import Post from '../../../../Services/Post/PostService';
import MediaData from '../../../../Services/MediaData/MediaDataService';

interface IMosaic {
    size: string
    index: number
    publication: PostModel
    watchElement: Function
}

const Mosaic = ({ size, index, publication, watchElement }: IMosaic) => {
    const navigate = useNavigate();
    const [image, setImage] = useState<ImageModel>()
    const [imageProfile, setImageProfile] = useState<ImageModel>()

    useEffect(() => {
        handleSrc(publication.idMediaData!, 0)
        handleSrc(publication.idMediaDataProfile!, 1)
    }, [publication.id])

    const handleSrc = (id: string, typeimg: number) => {
        const image = searchImage(id)
        if (image.length > 0) {
            if (typeimg == 0) { setImage(image[0]) }
            if (typeimg == 1) { setImageProfile(image[0]) }
        }
        else {
            MediaData.GetById(id)
                .then((e: any) => {
                    handleImageBank(e.data)
                    if (typeimg == 0) { setImage(e.data) }
                    if (typeimg == 1) { setImageProfile(e.data) }
                })
                .catch((err: any) => {
                    console.error(err)
                })
        }
    }


    const Context = useContext(AppContext);
    if (!Context) {
        return null;
    }
    const { searchImage, handleImageBank } = Context;

    return (<>
        {image
            ? <div className={`photo-tile ${size}`}>
                <div
                    className="user-info"
                    onClick={() => { navigate(`/${publication.username}`, { replace: true }) }}
                >
                    <div className="user-avatar">
                        <img src={imageProfile ? imageProfile.value : photoSrc} alt="Avatar" />
                    </div>
                    <div>
                        <span className="username">@{publication.username}</span>
                    </div>
                </div>
                <img onClick={()=> watchElement(index, "Mosaic")} src={image?.value} alt={`Imágen ${index}`} />
            </div>
            : <div key={index} style={{ height: '200px' }} className={`photo-tile ${size} color-changing `} />
        }
    </>
    )
}

export default Mosaic
import React, { useContext, useEffect } from "react";
import Interactor from "../Modules/Interactor/Interactor";
import { AppContext } from "../context/AppContext";
import Auth from "../Services/Auth/AuthService";

const Explorer = () => {
  useEffect(() => {
    document.title = `Explorador • ${process.env.REACT_APP_TITTLE_BASE}`;
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, []);

  const { searchVisible, isOnline } = useContext(AppContext) || {};

  useEffect(() => {
    if (Auth._User?.user?.id && Auth._User?.user?.username) {
      isOnline!(Auth._User.user.id, Auth._User.user.username);
    }
  }, [isOnline]);

  if (searchVisible) return null;

  return <Interactor />;
};

export default Explorer;

import React, { useEffect } from 'react'
import './ayuda.css'
import ComunityNav from '../../Modules/Nav/ComunityNav/ComunityNav'
import RestablacerPhoto from '../../assets/comunity_image/RESTABLECER.png'
import SignUpPhoto from '../../assets/comunity_image/SIGNUP.jpeg'
import PostAPhoto from '../../assets/comunity_image/POST_A.jpeg'
import PostBPhoto from '../../assets/comunity_image/POST_B.jpeg'
import RecortePhoto from '../../assets/comunity_image/RECORTE.png'
import HistoryAPhoto from '../../assets/comunity_image/HISTORYa_A.jpeg'
import HistoryBPhoto from '../../assets/comunity_image/HISTORYa_B.jpeg'
import HistoryCutPhoto from '../../assets/comunity_image/HISTORY_CUT.png'
import PasswordPhoto from '../../assets/comunity_image/PASSWORD.png'
import SendEmailPhoto from '../../assets/comunity_image/SEND_EMAIL.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const Ayuda = () => {

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            const target = e.target as HTMLElement;

            if (target.classList.contains('carita')) {
                target.parentElement?.classList.toggle('scale');
                target.children[1]?.classList.toggle('rotate');
            }

            if (target.classList.contains('link')) {
                const carita = target.parentElement;
                carita?.parentElement?.classList.toggle('scale');
                carita?.children[1]?.classList.toggle('rotate');
            }

            if (target.classList.contains('flecha')) {
                const carita = target.parentElement;
                carita?.parentElement?.classList.toggle('scale');
                carita?.children[1]?.classList.toggle('rotate');
            }
        };

        const contein = document.getElementById('contein');
        contein?.addEventListener('click', handleClick);

        return () => {
            contein?.removeEventListener('click', handleClick);
        };
    }, []);
    return (
        <>
            <br />
            <br />
            <div className="col-md-8 config-container" id="contein">
                <h3>Ayuda</h3>
                <hr />
                <div className="menu">
                    <ul>
                        <li className='list'>
                            <div className='carita'>
                                <span className='link'>Como me registro?</span><FontAwesomeIcon icon={faChevronDown}/>
                            </div>
                            <p>El proceso de registro es muy facil solo tienes que
                                completar todos los campos de ese simple formulario, y asi ser parte de nuestra comunidad, que esperas ven y <a href='../signup.php'>registrate</a>.
                                <img src={SignUpPhoto} alt='' />
                            </p>
                        </li>
                        <li className='list'>
                            <div className='carita'>
                                <span className='link'>Como subir un post?</span><FontAwesomeIcon icon={faChevronDown}/>
                            </div>
                            <p>
                                <br />
                                <br />
                                <div className='parrafoCompa'>
                                    <div className='duple'>
                                        <p>
                                            Los post son imagenes que subes a tu muro y que se quedan guardadas en tu perfil y que quienes te siguen pueden ver e interactuar.
                                            <br />
                                            <br />
                                            Para subir un post en Aycoro solo tienes que clikear en el icono de mas que aparece en la pantalla.
                                            <br />
                                            En dispositivos moviles en la parte superior y para PC en la parte inferior izquierda de la pantalla.
                                        </p>
                                    </div>
                                    <div className='duple'>
                                        <img className='ceo_img' src={PostAPhoto} alt='' />
                                        <b className='name'>Iconos en pc</b>
                                        <img className='ceo_img' src={PostBPhoto} alt='' />
                                        <b className='name'>Iconos en dispositivos moviles</b>
                                    </div>
                                    <br />

                                </div>
                                <br />
                                <img className='ceo_img' src={RecortePhoto} alt='' />
                                <p>
                                    Luego recostas la parte que quieres que salga en tu foto y ponerle un comentarios si asi lo deceas,
                                    al final clikeas publicar y esperas que carge.
                                </p>
                            </p>
                        </li>
                        <li className='list'>
                            <div className='carita'>
                                <span className='link'>Como subir una historia?</span><FontAwesomeIcon icon={faChevronDown}/>
                            </div>
                            <p>
                                <br />
                                <br />
                                <div className='parrafoCompa'>
                                    <div className='duple'>
                                        <p>
                                            Las historias son imagenes que publicas con una duracion de 24 horas y que solo la pueden ver tus seguidores,
                                            estas se pueden ver en la pagina principal en la parte superior encima de las publicaciones.
                                            <br />
                                            <br />
                                            Para subir una historia en Aycoro solo tienes que clikear en el icono con forma de reloj que aparece en la pantalla.
                                            <br />
                                            En dispositivos moviles en la parte superior y para PC en la parte inferior izquierda de la pantalla.
                                            Ademas el la parte superior si no tienes historias te permitira subir una en el circulo de tu historia.
                                        </p>
                                    </div>
                                    <div className='duple'>
                                        <img className='ceo_img' src={HistoryAPhoto} alt='' />
                                        <b className='name'>Iconos en pc</b>
                                        <img className='ceo_img' src={HistoryBPhoto} alt='' />
                                        <b className='name'>Iconos en dispositivos moviles</b>
                                    </div>
                                    <br />

                                </div>
                                <br />
                                <img className='ceo_img' src={HistoryCutPhoto} alt='' />
                                <p>
                                    Luego recostas la parte que quieres que salga en tu historia, clikeas publicar y esperas que carge.
                                </p>
                            </p>
                        </li>
                        <li className='list'>
                            <div className='carita'>
                                <span className='link'>Como puedo recuperar mi cuenta?</span><FontAwesomeIcon icon={faChevronDown}/>
                            </div>
                            <p>
                                Todos alguna ves hemos olvidado nuestra contraseña de algun sitio web y es un dolor de cabeza cuando no sabemos que hacer.
                                Si olvidas la contraseña de tu cuenta en Aycoro estos son los pasos para recuperar tu cuenta.
                                <div className='parrafoCompa'>
                                    <div className='duple'>
                                        <p>

                                            <br />
                                            <br />
                                            1.En la sección de iniciar sesion clikeas donde dice 'Olvidaste tu contraseña?' debajo de recuatro de la contraseña.
                                            <br />
                                            <br />
                                            2.Luego debes colocar el email con el cual te registraste en Aycoro y clikear en envias,
                                            esto enviara un link a tu email donde podras cambiar tu contraseña por una nueva contraseña.
                                            <br />
                                            <br />
                                            3.Abre tu correo y clikeas el link que se ha enviado y restablece tu contraseña,
                                            primero escribe una nueva contraseña y luego confirma esa nueva contraseña.
                                            <br />
                                            <br />

                                        </p>
                                    </div>
                                    <div className='duple'>
                                        <br />
                                        <br />
                                        <b className='name'>Paso 1</b>
                                        <img className='ceo_img' src={PasswordPhoto} alt='' />
                                        <br />
                                        <br />
                                        <b className='name'>Paso 2</b>
                                        <img className='ceo_img' src={SendEmailPhoto} alt='' />
                                    </div>
                                    <br />

                                </div>
                                <br />
                                <b className='name'>Paso 3</b>
                                <img className='ceo_img' src={RestablacerPhoto} alt='' />
                                <p>
                                    Al final inicias sesion con tu correo o usuario y tu nueva contraseña.
                                </p>
                            </p>
                        </li>
                        <li className='list'>
                            <div className='carita'>
                                <span className='link'>Solicitar ayuda o reportar un problema</span><FontAwesomeIcon icon={faChevronDown}/>
                            </div>
                            <p>
                                Envianos un mensaje acerca de tu situacion y te responderemos lo mas rapido posible atraves de la bandeja de mensajeria.
                                <form className='form_help' action='../keys/send-ayudaosug-key.php' method='post'>
                                    <textarea className='textare' name='mensaje' placeholder='Escribe aqui...' id=''></textarea>
                                    <input type='hidden' value='<?php echo $_SESSION[' name='id_sendner' />
                                    <input type='hidden' value='<?php echo $id_admin; ?>' name='id_reciver' />
                                    <input type='hidden' value='<?php echo $opc; ?>' name='opc' />
                                    <br />
                                    <button className='btn btn-success boton_help'>Enviar</button>
                                </form>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Ayuda

import React from 'react'
import { Link } from 'react-router-dom'
import IconLogo from "../../../assets/img/icon.png";
import ComunityLogo from "../../../assets/img/LOGOCOMUNITY.png";
import "./ComunityNav.css";

const ComunityNav = () => {
    return (
        <div>
            <div className="C_contain">
                <div className="row">
                    <div className="C_conteiner">
                        <div className="C_logoBox">
                            <div className="C_logo" title="Aycoro" onClick={() => window.location.pathname = "/"}>
                                <img src={ComunityLogo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="hr" />
        </div>
    )
}

export default ComunityNav
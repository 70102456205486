import React, { useContext, useEffect } from "react";
import HistoryComponet from "../Modules/Historys/HistoryComponet";
import Footer from "../Modules/Footer/Footer";
import { AppContext } from "../context/AppContext";
import RecordsExhaustedMessage from "../Modules/GoTo/RecordsExhaustedMessage";
import PublicationOnLoad from "../Modules/Publications/Publication/PublicationOnLoad";
import { PostModel } from "../Models/Post/PostModel";
import Publication from "../Modules/Publications/Publication/Publication";
import IndexPostCharger from "../Modules/Charger/IndexPostCharger/IndexPostCharger";
import { PostStatus } from "../constants/Status";
import Auth from "../Services/Auth/AuthService";

const ExplorerPage = () => {
  const {
    searchVisible,
    buttonsVisible,
    setButtonsVisible_F,
    historyList,
    setHistoryList_F,
    myhistorys,
    ConcatMyHistorys,
    setMyHistorys_F,
    setPhoto,
    setOnLoadPhoto,
    setFooterViewer_F,
    footerViewer,
    BdPost,
    OnLoadPost,
    setBdPost_F,
    chargingPost,
    goToExplorerFromIndex,
    isOnline,
  } = useContext(AppContext) || {};

  const updatePost = (
    id: string,
    updates: Partial<
      Pick<PostModel, "description" | "status" | "likes" | "comments" | "liked">
    >
  ) => {
    setBdPost_F!(
      BdPost!.map((post: PostModel) =>
        post.id === id ? { ...post, ...updates } : post
      )
    );
  };

  useEffect(() => {
    if (Auth._User?.user?.id && Auth._User?.user?.username) {
      isOnline!(Auth._User.user.id, Auth._User.user.username);
    }
  }, [isOnline]);

  if (searchVisible) return null;

  return (
    <>
      <HistoryComponet
        historyList={historyList!}
        setHistorys={setHistoryList_F!}
        myhistorys={myhistorys!}
        addMyHistory={ConcatMyHistorys!}
        setMyHistorys={setMyHistorys_F!}
      />
      <Footer
        buttonVisible={buttonsVisible!}
        setButtonsVisible={setButtonsVisible_F!}
        setPhotos={setPhoto!}
        setOnLoadPhoto={setOnLoadPhoto!}
        setFooterViewer={setFooterViewer_F!}
        footerViewer={footerViewer!}
        setMyHistorys={ConcatMyHistorys!}
      />
      {OnLoadPost && <PublicationOnLoad publication={OnLoadPost} />}
      {BdPost?.filter(
        (p: PostModel) =>
          p.status === PostStatus.EDITED || p.status === PostStatus.PUBLISHED
      ).map((p: PostModel, key: number) => (
        <Publication key={key} publication={p} updatePost={updatePost} />
      ))}
      {chargingPost && <IndexPostCharger />}
      {goToExplorerFromIndex && <RecordsExhaustedMessage />}
    </>
  );
};

export default ExplorerPage;

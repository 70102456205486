import './PerfilPostCharger.css'
import "../chargerColor.css"
export const PerfilPostCharger = () => {
    return (
        <div className='container container-post'>
            <div className='PhotoPost color-changing'>
            </div>
            <div className='PhotoPost color-changing'>
            </div>
            <div className='PhotoPost color-changing'>
            </div>
        </div>
    )
}



import React, { useEffect, useState } from "react";
import { MessagesModel } from "../../../../../Models/Message/MessagesModel";
import { MessageParams } from "../../../../../Models/Message/MessageParams";
import { UserPerfilModel } from "../../../../../Models/User/UserPerfilModel";
import UserProfile from "../../../../../assets/img/UserProfile.jpg";
import Auth from "../../../../../Services/Auth/AuthService";
import Chat_S from "../../../../../Services/Chat/ChatService";
import ChargerSlice from "../../../../ChargerSlice/ChargerSlice";
import "./MessageContainer.css";
import LeftChatTag from "./LeftChatTag";
import RightChatTag from "./RightChatTag";

interface IMessageContainer {
  userData: UserPerfilModel | undefined;
  messages?: any;
  idChat: string | undefined;
  setMessages: Function;
}
const MessageContainer = ({
  userData,
  messages,
  idChat,
  setMessages,
}: IMessageContainer) => {
  const messageRef = React.createRef<HTMLInputElement>();
  const [messagesOnDB, setMessagesOnDB] = useState<MessagesModel[]>([]);

  useEffect(() => {
    if (messageRef && messageRef.current) {
      const { scrollHeight, clientHeight } = messageRef.current;
      messageRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }
  }, [messages, messageRef]);

  useEffect(() => {
    if (idChat) {
      GetMessages(idChat);
    }
  }, [idChat]);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_TITTLE_BASE} • Chat (${userData?.user?.username})`;
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, [userData]);

  const GetMessages = (id: string | undefined) => {
    Chat_S.GetAllMessages(id!, 1)
      .then((res: any) => {
        setMessagesOnDB(res.data);
        setMessages([]);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <div className="chat-box" ref={messageRef}>
      {!idChat && <ChargerSlice />}
      {idChat && (
        <>
          {messagesOnDB.map((m: MessagesModel, index: number) => {
            return (
              <div key={index}>
                {m.idUser === Auth._User?.user?.id! && (
                  <RightChatTag
                    message={m.messageValue!}
                    createDate={m.createDate!}
                    type={m.type!}
                    idMedia={m.idMedia}
                  />
                )}

                {m.idUser === userData?.user?.id && (
                  <LeftChatTag
                    message={m.messageValue!}
                    createDate={m.createDate!}
                    user={userData!}
                    type={m.type!}
                    idMedia={m.idMedia}
                  />
                )}
              </div>
            );
          })}
          {messages.map((m: MessageParams, index: number) => {
            if (m.idChat === idChat) {
              return (
                <div key={index}>
                  {m.idUserSender === Auth._User?.user?.id! && (
                    <RightChatTag
                      message={m.messageValue!}
                      createDate={new Date(m.createDate!)}
                      type={m.type!}
                      idMedia={m.idMedia}
                    />
                  )}

                  {m.idUserSender === userData?.user?.id && (
                    <LeftChatTag
                      message={m.messageValue!}
                      createDate={new Date(m.createDate!)}
                      user={userData!}
                      type={m.type!}
                      idMedia={m.idMedia}
                    />
                  )}
                </div>
              );
            }
          })}
        </>
      )}
    </div>
  );
};

export default MessageContainer;

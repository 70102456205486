import './HistoryCharger.css'
import "../chargerColor.css"

export const HistoryCharger = () => {
    return (
        <div className="bodyHistory" id="caja_de_historias">
            <div className="boxHistoryUser">
                <div className="imgHistoryBoxFull color-changing">
                </div>
                <div className='tittle color-changing'></div>
            </div>
        </div>
    )
}

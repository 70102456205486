import Http from "../Http/HttpClient";
import { IPostService } from "../../Interface/Post/IPostService";
import { PostParams } from "../../Models/Post/PostParams";

export class PostService implements IPostService {
  async GetById(id: string, iduser: string): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`api/Post/${id}?idUserLog=${iduser}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async GetMosaicoPost(id: string, section: number): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/Post/GetMosaicoPost?id=${id}&section=${section}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async GetTendenciasPost(id: string): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/Post/GetTendenciaPost?id=${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async GetArchivedPost(id: string, section: number): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/Post/GetArchivedPost?id=${id}&section=${section}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async Create(post: PostParams): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.post("/api/Post", post)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }

  async Update(post: PostParams): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.put("/api/Post", post)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }

  async GetForUser(
    Username: string,
    iduser: string,
    section: number
  ): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(
        `/api/Post/GetUserPost?username=${Username}&idUserLog=${iduser}&section=${section}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }

  async GetIndexPost(id: string, section: number): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/Post?id=${id}&section=${section}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }

  async GetAll(id: string, section: number): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/Post/GetExplorerPost?id=${id}&section=${section}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
}

const Post = new PostService();
export default Post;

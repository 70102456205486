import React from "react";
import "./ChargerSlice.css"

const ChargerSlice = () => {
  return (
    <>
      <div id="contenedo_carca">
        <div id="carga">
        </div>
      </div>
    </>
  );
};

export default ChargerSlice;

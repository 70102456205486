import { PostModel } from "../../Models/Post/PostModel";
import { UserModel } from "../../Models/User/UserModel";
import { UserPerfilData } from "../../Models/User/UserPerfilData";
import { UserPerfilModel } from "../../Models/User/UserPerfilModel";
import Http from "../Http/HttpClient";

class AuthService {
  _Token: string | undefined;
  _ID: string | undefined;
  _User?: UserPerfilModel;
  _Authenticated: boolean = false;
  _My_Posts?: PostModel[];

  constructor() {
    this._Token = localStorage.getItem("TK") ?? "";
    if (this._Token) {
      this._Authenticated = !this._Authenticated;
      this._ID = JSON.parse(localStorage.getItem("Us-Ac") ?? "");
    }
    else{
      this.removeTokenStorage()
    }
  }

  setUser(u:UserPerfilModel){
    this._User = u;
  }

  GetToken() {
    return this._Token;
  }

  GetUser() {
    if (this._User !== undefined) {
      return this._User;
    }
  }

  Authenticate(tk: any, user: UserPerfilModel) {
    this.setTokenStorage(tk, user);
    return true;
  }

  setTokenStorage(tk: any, u: UserPerfilModel) {
    localStorage.setItem("TK", tk);
    localStorage.setItem(
      "Us-Ac",
      JSON.stringify(u.user?.id)
    );
    this._Token = tk;
    // this._ID = u.User?.Id;
    this._User = u;
    this._Authenticated = !this._Authenticated;
  }

  removeTokenStorage() {
    localStorage.removeItem("TK");
    localStorage.removeItem("Us-Ac");
    this._Authenticated = false;
    this._Token = undefined;
    this._User = undefined;
  }

  ChangeProfilePhoto(ProfilePhoto: string) {
    if (!this._User?.user) {
      console.error("User is not defined");
      return;
    }

    const updatedUserLogged: UserPerfilModel = {
      ...this._User,
      profilePhoto: ProfilePhoto
    };

    this._User = updatedUserLogged;
  }

  ChangeCoverPhoto(CoverPhoto: string) {
    if (!this._User?.user) {
      console.error("User is not defined");
      return;
    }
    const updatedUserLogged: UserPerfilModel = {
      ...this._User,
      coverPhoto: CoverPhoto
    };

    this._User = updatedUserLogged;
  }

  TokenPayload(tk: any) {
    let config = {
      headers: {
        Authorization: "Bearer " + tk(),
      },
    };
    Http.post("http://localhost:8000/api/v1/get_token_payloads", config);
  }
}

const Auth = new AuthService();
export default Auth;

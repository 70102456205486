import React, { useEffect, useState } from "react";
import "./UserPerfil.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCircleChevronDown,
  faEnvelopeSquare,
  faGripHorizontal,
  faPencil,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import UserProfile from "../../assets/img/UserProfile.jpg";
import UserCover from "../../assets/img/UserCover.jpg";
import { UserPerfilModel } from "../../Models/User/UserPerfilModel";
import Auth from "../../Services/Auth/AuthService";
import Follow from "../../Services/Follow/FollowService";
import { useNavigate } from "react-router-dom";
import CropperC from "../Cropper/CropperC";
import PerfilOptions from "../PostOptions/PerfilOptions";
import ArchivedPublication from "../Publications/Publication/ArchivedPublication/ArchivedPublication";
import FollowsListModal from "../Modals/FollowsListModal";

interface IUserPerfil {
  userData: UserPerfilModel | undefined;
  cantOfFollow: number;
  setCantOfFollow: Function;
  isActiveSpinner: boolean;
  cantOfFollowings: number;
  setCantOfFollowings: Function;
  isfollow: boolean;
  setIsFollow: Function;
}

const UserPerfil = ({
  userData,
  cantOfFollow,
  setCantOfFollow,
  isActiveSpinner,
  cantOfFollowings,
  setCantOfFollowings,
  isfollow,
  setIsFollow,
}: IUserPerfil) => {
  const navigate = useNavigate();

  const [ChagedPhofilePhoto, setChagedPhofilePhoto] = useState<string>("");
  const [ChagedCoverPhoto, setChagedCoverPhoto] = useState<string>("");

  const [aspectRatio, setAspectRatio] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>("");
  const ImageRef = React.createRef<HTMLInputElement>();
  const [image, setImage] = useState<any>(null);

  const [ViewPPO, setViewPPO] = useState<boolean>(false);
  const [ArchivedView, setArchivedView] = useState<boolean>(false);
  const [followsModalView, setFollowsModalView] = useState<boolean>(false);
  const [followsModaltab, setFollowsModaltab] = useState<boolean>(false);

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // const handlePress = (url: string) => {
  //   Linking.openURL(url);
  // };

  const presentation =
    userData?.user?.perfilData?.presentation! &&
    userData?.user?.perfilData?.presentation!.trim() !== ""
      ? userData?.user?.perfilData?.presentation!.split(urlRegex)
      : "ghost presentation".split(urlRegex);

  const setFollow = (idUser: string, isFollow: boolean) => {
    Follow.FollowUnFollow(Auth._User?.user?.id, idUser, isFollow)
      .then((res: any) => {})
      .catch((e: any) => {
        console.error(e);
      });

    isfollow
      ? setCantOfFollow(cantOfFollow - 1)
      : setCantOfFollow(cantOfFollow + 1);
    setIsFollow(!isfollow);
  };

  const setFollowModal = (idUser: string, isFollowModal: boolean) => {
    Follow.FollowUnFollow(Auth._User?.user?.id, idUser, isFollowModal)
      .then((res: any) => {})
      .catch((e: any) => {
        console.error(e);
      });
    userData?.user?.id == Auth._User?.user?.id &&
      (isFollowModal
        ? setCantOfFollowings(cantOfFollowings - 1)
        : setCantOfFollowings(cantOfFollowings + 1));
  };

  const setPhoto = (photo: string) => {
    console.log(photo);
    aspectRatio === 1
      ? setChagedPhofilePhoto(photo)
      : setChagedCoverPhoto(photo);
  };

  const OnImageChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      setInputValue(e.target.value);
      var img = e.target.files[0];
      setImage(img);
    }
  };

  useEffect(() => {
    // Cambiar el título de la página cuando el componente se monta
    document.title = `${userData?.user?.name} (${userData?.user?.username})`;

    // También puedes restablecer el título cuando el componente se desmonta
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, []);

  return (
    <>
      <div className="container col-md-7 UserPerfi">
        <div className="PerfilUser">
          {ChagedPhofilePhoto ? (
            <img src={ChagedPhofilePhoto} alt="" />
          ) : userData?.profilePhoto ? (
            <img src={userData?.profilePhoto} alt="" />
          ) : (
            <img src={UserProfile} alt="" />
          )}
          {userData?.user?.id == Auth._User?.user?.id && (
            <FontAwesomeIcon
              id="editperfilicon"
              icon={faPencil}
              onClick={() => {
                setAspectRatio(1);
                ImageRef.current?.click();
              }}
            />
          )}
          <input
            type="file"
            name="Post"
            id="PostId"
            accept="image/*"
            style={{ display: "none" }}
            ref={ImageRef}
            value={inputValue}
            onChange={(e) => {
              OnImageChange(e);
            }}
          />
        </div>
        <div className="InfoUserPerfil">
          <h4>{userData?.user?.name}</h4>
          <b>@{userData?.user?.username}</b>
          {userData?.user?.id != Auth._User?.user?.id && (
            <>
              {isfollow && (
                <div className="followBox">
                  <div
                    className="followed"
                    onClick={() => setFollow(userData?.user?.id!, isfollow)}
                  >
                    <FontAwesomeIcon
                      className="iconfollow"
                      icon={faUserCheck}
                    />
                  </div>
                  <div
                    className="buttonsFolloed"
                    onClick={() => {
                      navigate(`/Inbox/${userData?.user?.username}`, {
                        replace: true,
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      className="iconMsgPerfil"
                      icon={faEnvelopeSquare}
                    />
                    <b className="sendMsg">Enviar Mensaje</b>
                  </div>
                </div>
              )}

              {!isfollow && (
                <div className="NOfollowBox">
                  <div
                    className="follow"
                    onClick={() => setFollow(userData?.user?.id!, isfollow)}
                  >
                    <b>Seguir</b>
                  </div>
                </div>
              )}
            </>
          )}

          <div>
            <b
              style={{ cursor: "pointer" }}
              onClick={() => {
                setFollowsModalView(true);
                setFollowsModaltab(true);
              }}
            >
              {cantOfFollow} Seguidores{" "}
            </b>
            <span>|</span>
            <b
              style={{ cursor: "pointer" }}
              onClick={() => {
                setFollowsModalView(true);
                setFollowsModaltab(false);
              }}
            >
              {" "}
              {cantOfFollowings} Seguidos
            </b>
          </div>
          <div>
            {userData?.user?.perfilData?.presentation! &&
              userData?.user?.perfilData?.presentation!.trim() !== "" && (
                <span>
                  {presentation!.map((part, index) => {
                    if (urlRegex.test(part)) {
                      return (
                        <span
                          onClick={() => window.open(part, "_blank")}
                          style={{ color: "#065fd4", cursor: "pointer" }}
                        >
                          {part}
                        </span>
                      );
                    }
                    return <span key={index}>{part}</span>;
                  })}
                </span>
              )}
          </div>
        </div>
        {Auth._User?.user?.id == userData?.user?.id && (
          <>
            <div
              title="Opciones"
              style={{ cursor: "pointer" }}
              onClick={() => setViewPPO(!ViewPPO)}
            >
              <FontAwesomeIcon
                style={{ margin: "10px", fontSize: "20px" }}
                icon={faBars}
              />
            </div>
            {ViewPPO && (
              <PerfilOptions
                setViewPPO={setViewPPO}
                setArchivedView={setArchivedView}
              />
            )}
          </>
        )}
      </div>
      <hr />

      {ArchivedView && (
        <ArchivedPublication setArchivedView={setArchivedView} />
      )}

      {followsModalView && (
        <FollowsListModal
          setFollowsModalView={setFollowsModalView}
          userID={userData?.user?.id!}
          tab={followsModaltab}
          setFollowHandle={setFollowModal}
        />
      )}

      {image && (
        <CropperC
          src={URL.createObjectURL(image)}
          setImage={setImage}
          setInputValue={setInputValue}
          aspectRatio={aspectRatio}
          optionCut={2}
          setPhotos={setPhoto}
        />
      )}
    </>
  );
};

export default UserPerfil;

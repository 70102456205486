import React, { useEffect, useRef } from 'react'
import './MessageContainer.css'


const BandejaChatStart = () => {
    return (
        <div className='chat-box'>
            <div className='Tittle_message_Initial'>
                <h1>Bandeja de Mensajes</h1>
            </div>
        </div>
    )
}

export default BandejaChatStart
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Icon from "../assets/img/icon.png";
import Logo from "../assets/img/Logo.png";
import Auth from "../Services/Auth/AuthService";
import { AppContext } from "../context/AppContext";

const NotFountPage = () => {
  const { isOnline } = useContext(AppContext) || {};

  useEffect(() => {
    document.title = `${process.env.REACT_APP_TITTLE_BASE} • Página no encontrada`;
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, []);

  useEffect(() => {
    const user = Auth._User?.user;
    if (user?.id && user?.username) {
      isOnline!(user.id, user.username);
    }
  }, [isOnline]);

  const imageStyles: React.CSSProperties = {
    width: "100%",
    objectFit: "cover", 
    objectPosition: "center center",
  };
  
  const containerStyles: React.CSSProperties = {
    margin: "auto",
    marginTop: "100px",
    maxWidth: "200px",
    maxHeight: "90%",
  };

  return (
    <div>
      <div style={containerStyles}>
        <img src={Icon} style={imageStyles} />
        <br />
        <img src={Logo} style={{ ...imageStyles, marginTop: "-40px" }} />
      </div>
      <div style={{ textAlign: "center" }}>
        <h3>Página no encontrada</h3>
        <b>
          Esta página no existe o es posible que haya sido eliminada.{" "}
          <Link to="/">
            <span>Volver a Aycoro.</span>
          </Link>
        </b>
      </div>
    </div>
  );
};

export default NotFountPage;

import React from 'react'
import { Route } from 'react-router-dom'
import ComunityLayout from '../Layout/Comunity/ComunityLayout'
import Aycoro from '../Pages/Comunity/Aycoro'
import Ayuda from '../Pages/Comunity/Ayuda'
import Contacto from '../Pages/Comunity/Contacto'
import Terminos from '../Pages/Comunity/Terminos'
import CustomRoutes from './CustomRoutes'

const ComunityRoutes = () => {
    return (
        <CustomRoutes>
            <Route element={<ComunityLayout />}>
                <Route path="aycoro" element={<Aycoro />} />
                <Route path="ayuda" element={<Ayuda />} />
                <Route path="contacto" element={<Contacto />} />
                <Route path="terminos" element={<Terminos />} />
            </Route>
        </CustomRoutes>
    )
}

export default ComunityRoutes
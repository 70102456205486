import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import FooterBlog from '../../Modules/FooterBlog/FooterBlog'
import ComunityNav from '../../Modules/Nav/ComunityNav/ComunityNav'

const ComunityLayout = () => {
    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITTLE_BASE} • Comunity`;
        return () => {
            document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
        };
    }, []);
    return (
        <>
            <ComunityNav />
            <Outlet />
            <FooterBlog />
        </>
    )
}

export default ComunityLayout
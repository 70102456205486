import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import { PostModel } from '../Models/Post/PostModel';
import { UserPerfilModel } from '../Models/User/UserPerfilModel';
import Auth from '../Services/Auth/AuthService';
import Follow from '../Services/Follow/FollowService';
import Post from '../Services/Post/PostService';
import User from '../Services/User/UserService';
import MediaData from '../Services/MediaData/MediaDataService';
import { useImageBankHook } from './useImageBankHook';

export const useUserHook = () => {
    let { username } = useParams();
    const [perfilPost, setPerfilPost] = useState<PostModel[]>([])
    const [perfiluser, setPerfilUser] = useState<UserPerfilModel | undefined>()

    const [cantOfFollow, setCantOfFollow] = useState<number>();
    const [cantOfFollowings, setCantOfFollowings] = useState<number>();
    const [isActiveSpinner, setisActiveSpinner] = useState<boolean>(true);
    const [isPostCharging, setIsPostCharging] = useState<boolean>(true);
    const [isfollow, setIsFollow] = useState<boolean>(false)

    const [usernameDta, setUsernameDta] = useState<string>("")
    const [usernamePost, setUsernamePost] = useState<string>("")

    const [perfilPostSection, setPerfilPostSection] = useState<number>(0);
    const [bool, setBool] = useState<boolean>(true);
    const perfil = document.getElementById("app_contain");

    const handleScroll = () => {
        const scrollTop = perfil?.scrollTop || 0;
        const scrollHeight = perfil?.scrollHeight || 0;
        if (scrollTop > scrollHeight / 9 && bool && window.location.pathname != '/' && window.location.pathname != '/explorer') {
            setBool(false);
            setIsPostCharging(true);
            getHandlePerfilPost(username!, Auth._User?.user?.id!, perfilPostSection + 6);
            setPerfilPostSection((prevIndex) => prevIndex + 6);
        }
    };

    useEffect(() => {
        perfil?.addEventListener("scroll", handleScroll);

        return () => {
            perfil?.removeEventListener("scroll", handleScroll);
        };
    }, [bool]);

    const {
        searchImage,
        handleImageBank
    } = useImageBankHook();


    const getPerfilDta = (username: string) => {
        if (usernameDta !== username) {
            setisActiveSpinner(true)
            setUsernameDta(username)
            fetchUserData(username)

        }
    }

    const handleSrc = (id: string): string => {
        const image = searchImage(id)
        if (image.length > 0) {
            return image[0].value!;
        }
        return ""
    }

    const fetchUserData = async (username: string) => {
        try {
            const e = await User.GetUserByUsername(username);
            if (e.status === 200) {

                let profilePhotoValue = '';
                let coverPhotoValue = '';

                // Solo hacemos las consultas si los IDs no son nulos
                if (e.data.perfilData.idMediaDataProfile) {
                    const imageBank = handleSrc(e.data.perfilData.idMediaDataProfile);
                    if (imageBank) {
                        profilePhotoValue = imageBank;
                    }
                    else {
                        const profilePhotoResponse = await MediaData.GetById(e.data.perfilData.idMediaDataProfile);
                        if (profilePhotoResponse.data) {
                            profilePhotoValue = profilePhotoResponse.data.value;
                            handleImageBank(profilePhotoResponse.data)
                        }
                    }
                }

                if (e.data.perfilData.idMediaDataCover) {
                    const imageBank = handleSrc(e.data.perfilData.idMediaDataCover);
                    if (imageBank) {
                        coverPhotoValue = imageBank;
                    }
                    else {
                        const coverPhotoResponse = await MediaData.GetById(e.data.perfilData.idMediaDataCover);
                        if (coverPhotoResponse.data) {
                            coverPhotoValue = coverPhotoResponse.data.value;
                            handleImageBank(coverPhotoResponse.data)
                        }
                    }
                }


                const user: UserPerfilModel = {
                    user: {
                        id: e.data.id,
                        name: e.data.name,
                        username: e.data.username,
                        email: e.data.email,
                        password: undefined,
                        phone: e.data.phone,
                        birthday: e.data.birthday,
                        gender: e.data.gender,
                        status: e.data.status,
                        verify: e.data.verify,
                        perfilData: {
                            presentation: e.data.perfilData.presentation,
                            idMediaDataProfile: e.data.perfilData.idMediaDataProfile,
                            idMediaDataCover: e.data.perfilData.idMediaDataCover,
                        },
                        createDate: new Date(e.data.createDate),
                    },
                    isFollow: e.data.isFollow,
                    profilePhoto: profilePhotoValue,
                    coverPhoto: coverPhotoValue,
                    seguidores: e.data.seguidores,
                    seguidos: e.data.seguidos
                };
                setPerfilUser(user);
                setIsFollow(e.data.isFollow);
                setCantOfFollow(e.data.seguidores);
                setCantOfFollowings(e.data.seguidos);
                setisActiveSpinner(false);
            }
        } catch (err) {
            console.error("Error fetching user data:", err);
        }
    };


    const getPerfilPost = (username: string) => {
        if (usernamePost !== username) {
            setPerfilPost([])
            setUsernamePost(username)
            getHandlePerfilPost(username, Auth._User?.user?.id!, 1)
        }
    }


    const getHandlePerfilPost = (username: string, iduser: string, section: number): any => {
        Post.GetForUser(username!, iduser, section)
            .then((e: any) => {
                setPerfilPost(prevBdPost => [...prevBdPost, ...e.data]);
                if (e.data.length != 0) {
                    setBool(true);
                }
                setIsPostCharging(false)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    return {
        perfilPost,
        setPerfilPost,
        perfiluser,
        getPerfilPost,
        getPerfilDta,
        cantOfFollow,
        setCantOfFollow,
        cantOfFollowings,
        setCantOfFollowings,
        isActiveSpinner,
        isPostCharging,
        isfollow,
        setIsFollow,
    }
}

import { faChevronLeft, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserPerfilModel } from "../../../../Models/User/UserPerfilModel";
import Auth from "../../../../Services/Auth/AuthService";
import Chat_S from "../../../../Services/Chat/ChatService";
import UserProfile from "../../../../assets/img/UserProfile.jpg";
import User from "../../../../Services/User/UserService";
import "./Chat.css";
import BandejaChatStart from "./MessageContainer/BandejaChatStart";
import MessageContainer from "./MessageContainer/MessageContainer";
import SendMessageForm from "./SendMessageForm/SendMessageForm";
import { useImageBankHook } from "../../../../hooks/useImageBankHook";
import MediaData from "../../../../Services/MediaData/MediaDataService";
import IconVerify from "../../../../assets/img/verified-badge-profile-icon-png.webp";
import { VerificationStatus } from "../../../../constants/Status";

interface IChat {
  usersConnecting: [string];
  messages: any;
  setMessages: Function;
  setchatPlease: Function;
}

const Chat = (props: IChat) => {
  let { username } = useParams();
  const navigate = useNavigate();
  const [conversationSelect, setConversationSelect] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserPerfilModel>();
  const [idChat, setIdChat] = useState<string | undefined>();

  useEffect(() => {
    if (username === Auth._User?.user?.username) {
      navigate(`/Inbox`);
    }
    if (username !== undefined) {
      setConversationSelect(true);
    } else {
      setConversationSelect(false);
    }
  }, [username, navigate]);

  useEffect(() => {
    if (username !== undefined) {
      GetUser(username);
    }
  }, [username]);

  const { searchImage, handleImageBank } = useImageBankHook();

  const handleSrc = (id: string): string => {
    const image = searchImage(id);
    if (image.length > 0) {
      return image[0].value!;
    }
    return "";
  };

  const GetUser = async (username: string) => {
    try {
      const e = await User.GetUserByUsername(username);
      if (e.status === 200) {
        let profilePhotoValue = "";
        let coverPhotoValue = "";

        if (e.data.perfilData.idMediaDataProfile) {
          const imageBank = handleSrc(e.data.perfilData.idMediaDataProfile);
          if (imageBank) {
            profilePhotoValue = imageBank;
          } else {
            const profilePhotoResponse = await MediaData.GetById(
              e.data.perfilData.idMediaDataProfile
            );
            if (profilePhotoResponse.data) {
              profilePhotoValue = profilePhotoResponse.data.value;
              handleImageBank(profilePhotoResponse.data);
            }
          }
        }

        if (e.data.perfilData.idMediaDataCover) {
          const imageBank = handleSrc(e.data.perfilData.idMediaDataCover);
          if (imageBank) {
            coverPhotoValue = imageBank;
          } else {
            const coverPhotoResponse = await MediaData.GetById(
              e.data.perfilData.idMediaDataCover
            );
            if (coverPhotoResponse.data) {
              coverPhotoValue = coverPhotoResponse.data.value;
              handleImageBank(coverPhotoResponse.data);
            }
          }
        }

        const user: UserPerfilModel = {
          user: {
            id: e.data.id,
            name: e.data.name,
            username: e.data.username,
            email: e.data.email,
            password: undefined,
            phone: e.data.phone,
            birthday: e.data.birthday,
            gender: e.data.gender,
            status: e.data.status,
            verify: e.data.verify,
            perfilData: {
              presentation: e.data.perfilData.presentation,
              idMediaDataProfile: e.data.perfilData.idMediaDataProfile,
              idMediaDataCover: e.data.perfilData.idMediaDataCover,
            },
            createDate: new Date(e.data.createDate),
          },
          isFollow: e.data.isFollow,
          profilePhoto: profilePhotoValue,
          coverPhoto: coverPhotoValue,
          seguidores: e.data.seguidores,
          seguidos: e.data.seguidos,
        };
        setUserData(user);
        IsExist(e.data.id);
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  const IsExist = (id: string) => {
    Chat_S.IsExist(Auth._User?.user?.id, id)
      .then((res: any) => {
        setIdChat(res.data);
      })
      .catch((e: any) => {
        console.error(e);
      });
  };
  const active = props.usersConnecting.find(
    (element: string) => element === username
  );

  return (
    <div className="chatbox">
      {conversationSelect && (
        <>
          <div className="headerChat">
            <div
              className="icondiv"
              onClick={() => {
                props.setchatPlease(true);
                navigate(`/inbox`);
              }}
            >
              <FontAwesomeIcon className="iconback" icon={faChevronLeft} />
            </div>
            <div
              className="imgchatuser"
              onClick={() => {
                navigate(`/${username}`);
              }}
            >
              {userData?.profilePhoto ? (
                <img src={userData.profilePhoto} alt="" />
              ) : (
                <img src={UserProfile} alt="" />
              )}
              {active && <div className="greenPoint"></div>}
            </div>
            <div
              className="namechatuser"
              onClick={() => {
                navigate(`/${username}`);
              }}
            >
              <b>
                {userData?.user?.name}
                {userData?.user?.verify == VerificationStatus.VERIFIED && <img src={IconVerify} style={{ width: "15px" }} alt="" />}
              </b>
              <br />
              {active && <span>activo(a) ahora</span>}
            </div>
            <div className="iconchatbox">
              <FontAwesomeIcon title="Informacion" icon={faCircleInfo} />
            </div>
          </div>
          <MessageContainer
            userData={userData}
            messages={props.messages}
            idChat={idChat}
            setMessages={props.setMessages}
          />
          <SendMessageForm
            userData={userData}
            idChat={idChat}
          />
        </>
      )}

      {!conversationSelect && (
        <>
          <BandejaChatStart />
        </>
      )}
    </div>
  );
};

export default Chat;

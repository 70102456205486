import React, { useEffect, useState } from "react";
import { UserPerfilModel } from "../../../../../Models/User/UserPerfilModel";
import UserProfile from "../../../../../assets/img/UserProfile.jpg";
import getHourHook from "../../../../../hooks/getHourHook";
import MediaData from "../../../../../Services/MediaData/MediaDataService";
import { useImageBankHook } from "../../../../../hooks/useImageBankHook";
import { MessageType } from "../../../../../constants/Types";
import Post from "../../../../../Services/Post/PostService";
import Auth from "../../../../../Services/Auth/AuthService";
import { PostModel } from "../../../../../Models/Post/PostModel";
import PublicationModalView from "../../../../Modals/PublicationModalView";

interface ILeftChatTag {
  message: string;
  createDate: Date;
  user: UserPerfilModel;
  type: string;
  idMedia: string | undefined;
}

const LeftChatTag = ({
  message,
  createDate,
  user,
  type,
  idMedia,
}: ILeftChatTag) => {
  const { getElapsedTime } = getHourHook();
  const [Photo, setPhoto] = useState<string>();
  const [publication, setPublication] = useState<PostModel>();
  const [isVisiblePublicationModalView, setVisiblePublicationModalView] =
    useState<boolean>(false);

  useEffect(() => {
    if (idMedia) {
      if (type == MessageType.IMAGE) {
        getMedia(idMedia);
      }
      if (type == MessageType.PUBLICATION) {
        getPost(idMedia);
      }
    }
  }, []);

  const { searchImage, handleImageBank } = useImageBankHook();

  const handleSrc = (id: string): string => {
    const image = searchImage(id);
    if (image.length > 0) {
      return image[0].value!;
    }
    return "";
  };

  const getMedia = async (id: any) => {
    try {
      if (id) {
        const imageBank = handleSrc(id);
        if (imageBank) {
          setPhoto(imageBank);
        } else {
          const profilePhotoResponse = await MediaData.GetById(id);
          if (profilePhotoResponse.data) {
            setPhoto(profilePhotoResponse.data.value);
            handleImageBank(profilePhotoResponse.data);
          }
        }
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  const getPost = async (id: string) => {
    Post.GetById(id, Auth._User?.user?.id!)
      .then((e: any) => {
        if (e.data) {
          setPublication(e.data);
          getMedia(e.data?.idMediaData);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const updatePublication = (
    id: string,
    updates: Partial<
      Pick<PostModel, "description" | "status" | "likes" | "comments" | "liked">
    >
  ) => {
    if (publication?.id === id) {
      setPublication((prev) => ({ ...prev!, ...updates }));
    }
  };

  return (
    <>
      {type == MessageType.PUBLICATION && (
        <div
          style={{
            width: "300px",
            height: "380px",
            border: "1px solid gray",
            borderRadius: "25px",
            paddingTop: "10px",
            cursor: "pointer",
            marginLeft: "auto",
          }}
          onClick={() => setVisiblePublicationModalView(true)}
        >
          <b style={{ marginLeft: "10px" }}>{publication?.username}</b>
          {Photo && (
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
                objectPosition: "center",
              }}
              src={Photo}
              alt="Selected"
            />
          )}
          <p>{publication?.description}</p>
        </div>
      )}

      <div className="chat incoming">
        <img
          style={{ height: "35px", width: "35px", borderRadius: "50%" }}
          src={user?.profilePhoto ? user.profilePhoto : UserProfile}
          alt=""
        />
        <div className="details">
          <p>
            {type == MessageType.IMAGE && (
              <div
                style={{
                  width: "300px",
                  height: "300px",
                }}
              >
                {Photo && (
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                    src={Photo}
                    alt="Selected"
                  />
                )}
              </div>
            )}
            {message}
            <span className="time_date intime">
              {getElapsedTime(new Date(createDate))}
            </span>
          </p>
        </div>
      </div>
      {isVisiblePublicationModalView && (
        <PublicationModalView
          setModalVisible={setVisiblePublicationModalView}
          isModalVisible={isVisiblePublicationModalView}
          index={0}
          setIndex={() => {}}
          publications={[publication!]}
          setPublications={updatePublication}
        />
      )}
    </>
  );
};

export default LeftChatTag;

import React, { useState } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faPlus,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import CropperC from "../Cropper/CropperC";


interface IFooterState {
  buttonVisible: boolean;
  setButtonsVisible: Function;
  setPhotos: Function;
  setOnLoadPhoto: Function;
  footerViewer: boolean;
  setFooterViewer: Function;
  setMyHistorys: Function;
}

const Footer = (props: IFooterState) => {
  const [inputValue, setInputValue] = useState<string>("");
  const ImageRef = React.createRef<HTMLInputElement>();
  const [image, setImage] = useState<any>(null);
  const [aspectRatio, setAspectRatio] = useState<number>(0)

  const OnImageChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      setInputValue(e.target.value);
      var img = e.target.files[0];
      setImage(img);
    }
  };

  return (
    <>
      {props.footerViewer && (
        <div className="Footer_contein">
          {props.buttonVisible && (
            <div className="listInput">
              <div className="inputsBox">
                <div className="titleInputsBox">
                  <strong>Historia</strong>
                </div>
                <div className="inputs" onClick={() => {
                  setAspectRatio(3 / 5);
                  ImageRef.current?.click();
                }}>
                  <FontAwesomeIcon className="iconInput" icon={faClock} />
                </div>
              </div>
              <div className="inputsBox">
                <div className="titleInputsBox">
                  <strong>Publicacion</strong>
                </div>
                <div className="inputs" onClick={() => {
                  setAspectRatio(1);
                  ImageRef.current?.click();
                }}>
                  <FontAwesomeIcon className="iconInput" icon={faCamera} />
                </div>
              </div>
            </div>
          )}

          <div className="inputsBox push">
            <div
              className="inputs"
              onClick={() => props.setButtonsVisible(!props.buttonVisible)}
            >
              <FontAwesomeIcon className="iconInput" icon={faPlus} />
              <input
                type="file"
                name="Post"
                id="PostId"
                accept="image/*"
                style={{ display: "none" }}
                ref={ImageRef}
                value={inputValue}
                onChange={(e) => {
                  OnImageChange(e);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {!props.footerViewer && (
        <div className="Footer_contein">

          <div className="inputsBox push">
            <div
              className="inputs"
            >
              <FontAwesomeIcon className="iconInput" icon={faPlus} />
            </div>
          </div>
        </div>
      )}

      {image && (
        <CropperC
          src={URL.createObjectURL(image)}
          setImage={setImage}
          setInputValue={setInputValue}
          aspectRatio={aspectRatio}
          setPhotos={aspectRatio === 1 ? props.setPhotos : props.setMyHistorys}
          setOnLoadPhoto={props.setOnLoadPhoto}
          setFooterViewer={props.setFooterViewer}
          optionCut={1}
        />
      )}
    </>
  );
};

export default Footer;

const getHourHook = () => {
  const gethour = (date: Date): string => {
    return date.toLocaleTimeString("es-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const getElapsedTime = (value: Date): string => {
    const now = new Date();
    const diffTime = now.getTime() - new Date(value).getTime();

    const timeUnits = [
      { unit: "año", seconds: 31536000 },
      { unit: "mes", seconds: 2592000 },
      { unit: "semana", seconds: 604800 },
      { unit: "día", seconds: 86400 },
      { unit: "hora", seconds: 3600 },
      { unit: "minuto", seconds: 60 },
      { unit: "segundo", seconds: 1 },
    ];

    const getTimeString = (value: number, unit: string): string =>
      `Hace ${value} ${unit}${value !== 1 ? (unit === "mes" ? "es" : "s") : ""}`;

    const diffSeconds = Math.floor(diffTime / 1000);

    if (diffSeconds < 1) return "Justo ahora";

    for (const { unit, seconds } of timeUnits) {
      const diff = Math.floor(diffSeconds / seconds);
      if (diff >= 1) return getTimeString(diff, unit);
    }

    return "Justo ahora";
  };

  return {
    gethour,
    getElapsedTime,
  };
};

export default getHourHook;

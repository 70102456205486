import React, { useEffect, useState } from "react";
import "./Publication.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
    faComment,
    faEllipsisVertical,
    faHeart,
} from "@fortawesome/free-solid-svg-icons";
import Coment from "../Coments/Coments";
import { ComentModel } from "../../../Models/Coment/ComentModel";
import { PostModel } from "../../../Models/Post/PostModel";
import { PostParams } from "../../../Models/Post/PostParams";
import Auth from "../../../Services/Auth/AuthService";

interface IPublicationOnLoad {
    publication: PostParams
}

const PublicationOnLoad = (props: IPublicationOnLoad) => {
    return (
        <div className="container disable-div">
            <div className="row">
                <div className="col-md-6 publication">
                    <div className="_progressBar">
                    </div>
                    <div className="ImageBox">
                        <img src={props.publication.photo} />
                    </div>
                    <div className="InteratorPost">
                        <div className="LikeComentIcon">
                            <div className="LikeIcon">

                                <FontAwesomeIcon
                                    className="icon"
                                    icon={faHeart}
                                />
                            </div>
                            <div className="ComentnIcon">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={faComment}
                                />
                            </div>
                        </div>
                        <div className="settingIcon">
                            <FontAwesomeIcon className="icon" icon={faEllipsisVertical} />
                        </div>
                    </div>
                    <div className="statePublication">
                    </div>
                    <div className="statePublication">
                        <span>{props.publication.description}</span>
                    </div>
                    <div className="statePublication">
                        <strong className="userPublication">
                            {Auth._User?.user?.username}
                        </strong>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </div>
    )
}

export default PublicationOnLoad
import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Icon from '../../assets/img/icon.png';
import Logo from '../../assets/img/Logo.png';
import Auth from '../../Services/Auth/AuthService';
import User from '../../Services/User/UserService';
import './ScreenICon.css';
import MediaData from '../../Services/MediaData/MediaDataService';
import { UserPerfilModel } from '../../Models/User/UserPerfilModel';
import { AppContext } from '../../context/AppContext';
import { useImageBankHook } from '../../hooks/useImageBankHook';

const ScreenICon = () => {
  const [authPass, setAuthPass] = useState<boolean>(true);
  
  const fetchUserData = async (userID: string) => {
    try {
      const userResponse = await User.GetUserByID(userID);

      if (userResponse.status === 200) {
        const { perfilData, id, name, username, email, phone, birthday, gender, status, verify, createDate, isFollow, seguidos, seguidores } = userResponse.data;

        let profilePhotoValue = '';
        let coverPhotoValue = '';

        // Solo hacemos las consultas si los IDs no son nulos
        if (perfilData.idMediaDataProfile) {
          const profilePhotoResponse = await MediaData.GetById(perfilData.idMediaDataProfile);
          if (profilePhotoResponse.data) {
            profilePhotoValue = profilePhotoResponse.data.value;
            handleImageBank(profilePhotoResponse.data)
          }
        }

        if (perfilData.idMediaDataCover) {
          const coverPhotoResponse = await MediaData.GetById(perfilData.idMediaDataCover);
          if (coverPhotoResponse.data) {
            coverPhotoValue = coverPhotoResponse.data.value;
            handleImageBank(coverPhotoResponse.data)
          }
        }

        const user: UserPerfilModel = {
          user: {
            id,
            name,
            username,
            email,
            password: undefined,
            phone,
            birthday,
            gender,
            status,
            verify,
            perfilData: {
              presentation: perfilData.presentation,
              idMediaDataProfile: perfilData.idMediaDataProfile,
              idMediaDataCover: perfilData.idMediaDataCover,
            },
            createDate: new Date(createDate),
          },
          isFollow,
          profilePhoto: profilePhotoValue,
          coverPhoto: coverPhotoValue,
          seguidos,
          seguidores,
        };

        Auth.setUser(user);
        setAuthPass(false);
      }
    } catch (err) {
      console.error('Error fetching user data:', err);
    }
  };

  useEffect(() => {
    if (!Auth._User && Auth._ID) {
      fetchUserData(Auth._ID);
    } else {
      setAuthPass(false); // Si el usuario ya está autenticado, cambiamos el estado inmediatamente
    }
  }, []);

  const {
    searchImage,
    handleImageBank
  } = useImageBankHook();

  return (
    <>
      {authPass ? (
        <div className="_Icon_Container">
          <img src={Icon} alt="Icon" />
          <br />
          <img src={Logo} id="TextLogoScreen" alt="Logo" />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default ScreenICon;

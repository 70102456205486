import React, { useContext, useEffect, useState } from "react";
import "./HistoryComponet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronLeft,
  faChevronRight,
  faCircleChevronLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Auth from "../../Services/Auth/AuthService";
import UserProfile from "../../assets/img/UserProfile.jpg";
import { HistoryListItemModel } from "../../Models/History/HistoryListItemModel";
import CropperC from "../Cropper/CropperC";
import { HistoryModel } from "../../Models/History/HistoryModel";
import HistoryViewer from "./HistoryViewer/HistoryViewer";
import { HistoryCharger } from "../Charger/HistoryCharger/HistoryCharger";
import { AppContext } from "../../context/AppContext";

interface HistoryComponent {
  historyList: HistoryListItemModel[]
  setHistorys: Function
  myhistorys: HistoryListItemModel[]
  addMyHistory: Function
  setMyHistorys: Function
}

const HistoryComponet = (props: HistoryComponent) => {

  const [inputValue, setInputValue] = useState<string>("");
  const ImageRef = React.createRef<HTMLInputElement>();
  const [image, setImage] = useState<any>(null);
  const [viewHistoryBox, setViewHistoryBox] = useState<boolean>(false);
  const [viewMyHistoryBox, setViewMyHistoryBox] = useState<boolean>(false);
  const [indexUser, setIndexUser] = useState<number>();
  const [indexHistory, setIndexHistory] = useState<number>();


  useEffect(() => {
    if (props.myhistorys) {
      if (props.myhistorys![0].historysData![0] === undefined) {
        setViewMyHistoryBox(false)
      }
    }
  }, [props.myhistorys])


  useEffect(() => {
    var back = document.getElementById("caja_de_historias");
    document.querySelector('.back_buton_history')?.addEventListener('click', () => {
      back!.scrollLeft -= 100;
    })
    document.querySelector('.come_buton_history')?.addEventListener('click', () => {
      back!.scrollLeft += 100;
    })
  });

  const OnImageChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      setInputValue(e.target.value);
      var img = e.target.files[0];
      setImage(img);
    }
  };

  const Context = useContext(AppContext);
  if (!Context) {
    // Manejar el caso cuando el contexto es nulo
    return null;
  }
  const { chargingMyHistory, chargingOthersHistorys } = Context;

  return (
    <>
      <div className="container col-md-8">
        <div className="containHistory">
          <div>
            <div className="iconarrowsbox">
              <FontAwesomeIcon
                className="icon back_buton_history"
                icon={faChevronLeft}
              />
            </div>
          </div>
          {chargingMyHistory || chargingOthersHistorys
            ? <HistoryCharger />
            : <div className="bodyHistory" id="caja_de_historias">
              {props.myhistorys &&
                props.myhistorys[0].historysData?.length! < 1
                ?
                (
                  <div className="boxHistoryUser" onClick={() => {
                    ImageRef.current?.click();
                  }}>
                    <div className="imgHistoryBox">
                      {Auth._User?.profilePhoto ? <img src={Auth._User?.profilePhoto} alt="" /> : <img src={UserProfile} alt="" />}
                      <strong className="YourHistoryText">Tu Historia</strong>
                    </div>
                    <div className="iconPlusHystoryBox">
                      <FontAwesomeIcon className="iconHistoryPlus" icon={faPlus} />
                      <input
                        type="file"
                        name="Post"
                        id="PostId"
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={ImageRef}
                        value={inputValue}
                        onChange={(e) => {
                          OnImageChange(e);
                        }}
                      />
                    </div>
                  </div>
                )
                :
                (
                  <div className="boxHistoryUser" onClick={() => setViewMyHistoryBox(true)}>
                    <div className="imgHistoryBoxFull" style={{ background: 'linear-gradient(to bottom left,rgb(202, 5, 13),rgb(66, 73, 174))' }}>
                      {Auth._User?.profilePhoto ? <img src={Auth._User?.profilePhoto} alt="" /> : <img src={UserProfile} alt="" />}
                      <strong className="YourHistoryText">Tu Historia</strong>
                    </div>
                  </div>
                )
              }

              {props.historyList && props.historyList.length > 0 &&
                props.historyList.map((h: HistoryListItemModel, key: number) => (
                  <div key={key} className="boxHistoryUser" onClick={() => {
                    setIndexUser(key)
                    setIndexHistory(h.historysData?.indexOf(h.historysData?.find((his: HistoryModel) => his.seen === false)!) !== -1 ? h.historysData?.indexOf(h.historysData?.find((his: HistoryModel) => his.seen === false)!)! : 0)
                    setViewHistoryBox(true)
                  }}>
                    <div className="imgHistoryBoxFull" style={h.historysData![h.historysData!.length - 1].seen ? { background: 'grey' } : { background: 'linear-gradient(to bottom left,rgb(202, 5, 13),rgb(66, 73, 174))' }}>
                      {h.profilePhoto ? <img src={h.profilePhoto} alt="" /> : <img src={UserProfile} alt="" />}
                      <strong className="YourHistoryText">{h.username!.length > 11 ? h.username?.substr(0, 8) + '...' : h.username}</strong>
                    </div>
                  </div>
                ))
              }


            </div>
          }



          {viewHistoryBox && props.historyList.length > 0 && (
            <HistoryViewer
              Data={props.historyList}
              indexUser={indexUser!}
              indexHistory={indexHistory!}
              setViewHistoryBox={setViewHistoryBox}
              setHistorys={props.setHistorys}
            />
          )}

          {viewMyHistoryBox && props.myhistorys.length > 0 && props.myhistorys[0]!.historysData!.length > 0 && (
            <HistoryViewer
              Data={props.myhistorys}
              indexUser={0}
              indexHistory={0}
              setViewHistoryBox={setViewMyHistoryBox}
              setHistorys={props.setMyHistorys}
            />
          )}

          <div>
            <div className="iconarrowsbox">
              <FontAwesomeIcon
                className="icon come_buton_history"
                icon={faChevronRight}
              />
            </div>
          </div>
        </div>
      </div>

      {image && (
        <CropperC
          src={URL.createObjectURL(image)}
          setImage={setImage}
          setInputValue={setInputValue}
          aspectRatio={3 / 5}
          optionCut={1}
          setPhotos={props.addMyHistory}
        />
      )
      }
    </>

  );
}

export default HistoryComponet;

import React, { useContext, useEffect, useRef, useState } from "react";
import "./Publication.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faComment,
  faEllipsisVertical,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import Coments from "../Coments/Coments";
import { ComentModel } from "../../../Models/Coment/ComentModel";
import { PostModel } from "../../../Models/Post/PostModel";
import Like from "../../../Services/Like/LikeService";
import Auth from "../../../Services/Auth/AuthService";
import Coment from "../../../Services/Coments/ComentService";
import { ComentParams } from "../../../Models/Coment/ComentParams";
import Data from "../../../Services/Data/Data";
import { AppContext } from "../../../context/AppContext";
import { ImageModel } from "../../../Models/Post/ImageModel";
import MediaData from "../../../Services/MediaData/MediaDataService";
import ModalOptions from "../../Modals/ModalOptions";
import { ModalOptionModel } from "../../../Models/data/ModalOptionModel";
import Status from "../../../Services/Status/StatusService";
import getHourHook from "../../../hooks/getHourHook";
import { PostStatus } from "../../../constants/Status";

interface IPublicationsDetails {
  publication: PostModel;
  updatePost: Function;
}

const PublicationsDetails = React.memo(
  ({ publication, updatePost }: IPublicationsDetails) => {
    const navigate = useNavigate();
    const mentionRegex = /(@[^\s]+)/g;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const { getElapsedTime } = getHourHook();
    const [allComents, setAllComents] = useState<ComentModel[]>([]);
    const [inputComent, setInputComent] = useState<boolean>(false);
    const [seeComents, setSeeComents] = useState<boolean>(false);
    const [comentText, setComentText] = useState<string>("");
    const [spinnerComent, setSpinnerComent] = useState<boolean>(false);
    const [classlike, setClasslike] = useState<boolean>(false);
    const [image, setImage] = useState<ImageModel>();
    const [isModalOptionsVisible, setModalOptionsVisible] =
      useState<boolean>(false);

    useEffect(() => {
      if (classlike) {
        const timeout = setTimeout(() => {
          setClasslike(false);
        }, 1000);

        return () => clearTimeout(timeout);
      }
    }, [classlike]);

    const getComents = () => {
      Coment.GetAll(publication.id!, Auth._User?.user?.id!, 1)
        .then((e: any) => {
          setAllComents(e.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };

    const ActionLike = (idUser: string, idPost: string) => {
      publication.liked
        ? Like.RemoveLike(idUser, idPost)
            .then((e: any) => {})
            .catch((err: any) => {
              console.log(err);
            })
        : Like.Setlike(idUser, idPost)
            .then((e: any) => {})
            .catch((err: any) => {
              console.log(err);
            });
    };

    const handleKeyPress = (e: any) => {
      if (e.key == "Enter") {
        SaveComent();
      }
    };

    const SaveComent = () => {
      if (comentText) {
        setSpinnerComent(true);
        const C: ComentParams = {
          idcoment: "",
          idpost: publication.id,
          iduser: Auth._User?.user?.id,
          coment: comentText,
          createDate_Js: Data._Today.toISOString(),
        };
        Coment.Save(C)
          .then((e: any) => {
            setAllComents((allComents: any) => [...allComents, e.data]);
            updatePost(publication.id, {
              comments: publication.comments! + 1,
            });
            setComentText("");
            setInputComent(false);
            setSpinnerComent(false);
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    };

    const handleComentChange = (modifiedComent: ComentModel) => {
      const updatedComents = allComents.map((coment) => {
        if (coment.id === modifiedComent.id) {
          return modifiedComent;
        }
        return coment;
      });
      setAllComents(updatedComents);
    };

    const deleteComment = (indexToDelete: number) => {
      const updatedComments = allComents.filter(
        (_, index) => index !== indexToDelete
      );
      setAllComents(updatedComments);
      updatePost(publication.id, {
        comments: publication.comments! - 1,
      });
    };

    useEffect(() => {
      handleSrc(publication.idMediaData!);
    }, [publication.id]);

    const handleSrc = (id: string) => {
      const image = searchImage(id);
      if (image.length > 0) {
        setImage(image[0]);
      } else {
        MediaData.GetById(id)
          .then((e: any) => {
            setImage(e.data);
            handleImageBank(e.data);
          })
          .catch((err: any) => {
            console.error(err);
          });
      }
    };

    const DeletePost = (id: string) => {
      Status.ApplyStatusToDelete(id)
        .then((e: any) => {})
        .catch((err: any) => {
          console.error(err);
        });
    };

    const ArchivedPost = (id: string) => {
      Status.ApplyStatusToArchived(id)
        .then((e: any) => {})
        .catch((err: any) => {
          console.error(err);
        });
    };

    const DesArchived = (id: string) => {
      Status.ApplyStatusToActive(id)
        .then((e: any) => {})
        .catch((err: any) => {
          console.error(err);
        });
    };

    const Context = useContext(AppContext);
    if (!Context) {
      return null;
    }
    const { searchImage, handleImageBank } = Context;

    const options: ModalOptionModel[] = [
      {
        name: "Eliminar",
        function: () => {
          changeStatusPost(PostStatus.DELETED);
          DeletePost(publication.id!);
        },
        color: "red",
      },
      {
        name: "Editar",
        function: () => {},
        color: "",
      },
      ...(publication.status === PostStatus.EDITED ||
      publication.status === PostStatus.PUBLISHED
        ? [
            {
              name: "Archivar",
              function: () => {
                changeStatusPost(PostStatus.ARCHIVED);
                ArchivedPost(publication.id!);
              },
              color: "",
            },
          ]
        : []),
      ...(publication.status === PostStatus.ARCHIVED
        ? [
            {
              name: "Desarchivar",
              function: () => {
                changeStatusPost(PostStatus.PUBLISHED);
                DesArchived(publication.id!);
              },
              color: "",
            },
          ]
        : []),
    ];

    const likePost = (liked: boolean, amountOfLikes: number) => {
      updatePost(publication.id, {
        liked: liked,
        likes: amountOfLikes,
      });
    };

    const changeStatusPost = (status: number) => {
      updatePost(publication.id, {
        status: status,
      });
    };

    const handlePressMention = (mention: string) => {
      const username = mention.slice(1);
      navigate(`/${username}`);
    };

    const combinedRegex = new RegExp(
      `${urlRegex.source}|${mentionRegex.source}`,
      "g"
    );

    const stringDescription =
      publication?.description && publication?.description.trim() !== ""
        ? publication!.description!.split(combinedRegex)
        : "ghost description".split(combinedRegex);

    return (
      <>
        {publication.id && (
          <div
            key={publication.id}
            className="container list-item"
            id="publication.id"
          >
            <div className="row">
              <div className="col-md-10 publication publicationsFlex">
                <div className="col-md-8">
                  {image ? (
                    <div className="ImageBox">
                      <img
                        src={image?.value}
                        onDoubleClick={() => {
                          setClasslike(true);
                          if (!publication.liked) {
                            ActionLike(Auth._User?.user?.id!, publication.id!);
                            likePost(
                              !publication.liked,
                              publication.liked
                                ? publication.likes! - 1
                                : publication.likes! + 1
                            );
                          }
                        }}
                      />
                      <FontAwesomeIcon
                        style={{ color: "white" }}
                        className={classlike ? "like" : ""}
                        id="icon_Heart_like"
                        icon={faHeart}
                      />
                    </div>
                  ) : (
                    <div className="ImageBoxCharger color-changing" />
                  )}
                </div>
                <div className="col-md-4 PublicationInteractionPading">
                  <div className="InteratorPost">
                    <div className="LikeComentIcon">
                      <div
                        className="LikeIcon"
                        onClick={() => {
                          ActionLike(Auth._User?.user?.id!, publication.id!);
                          likePost(
                            !publication.liked,
                            publication.liked
                              ? publication.likes! - 1
                              : publication.likes! + 1
                          );
                        }}
                      >
                        {publication.liked && (
                          <FontAwesomeIcon
                            style={{ color: "red" }}
                            className="icon"
                            icon={faHeart}
                          />
                        )}
                        {!publication.liked && (
                          <FontAwesomeIcon className="icon" icon={faHeart} />
                        )}
                      </div>
                      <div
                        className="ComentnIcon"
                        onClick={() => setInputComent(!inputComent)}
                      >
                        <FontAwesomeIcon className="icon" icon={faComment} />
                      </div>
                    </div>
                    {publication.idUser == Auth._User?.user?.id && (
                      <div
                        className="settingIcon"
                        onClick={() => {
                          setModalOptionsVisible(true);
                        }}
                      >
                        <FontAwesomeIcon
                          className="icon"
                          icon={faEllipsisVertical}
                        />
                      </div>
                    )}
                  </div>
                  <div className="statePublication">
                    {publication.likes !== 0 && (
                      <span>
                        {publication.likes === 1
                          ? `${publication.likes} Like`
                          : `${publication.likes} Likes`}
                      </span>
                    )}
                  </div>
                  {publication.description &&
                    publication.description.trim() !== "" && (
                      <div className="statePublication">
                        <span>
                          {stringDescription.map((part, index) => {
                            // if (urlRegex.test(part)) {
                            //   return (
                            //     <TouchableOpacity
                            //       key={index}
                            //       onPress={() => handlePressUrl(part)}
                            //     >
                            //       <Text style={{ color: "blue" }}>{part}</Text>
                            //     </TouchableOpacity>
                            //   );
                            // }
                            if (mentionRegex.test(part)) {
                              return (
                                <b
                                  key={index}
                                  onClick={() => handlePressMention(part)}
                                  style={{ color: "green", cursor: "pointer" }}
                                >
                                  {part}
                                </b>
                              );
                            }
                            return <span key={index}>{part}</span>;
                          })}
                        </span>
                      </div>
                    )}
                  <div className="statePublication">
                    <strong
                      className="userPublication"
                      onClick={() => navigate(`/${publication.username}`)}
                    >
                      {publication.username}
                    </strong>
                  </div>
                  {publication.comments !== 0 && (
                    <div className="statePublication">
                      <span
                        className="NumComent"
                        onClick={() => {
                          setSeeComents(!seeComents);
                        }}
                      >
                        Ver{" "}
                        {publication.likes === 1
                          ? `comentario`
                          : `${publication.comments} comentarios`}
                      </span>
                    </div>
                  )}
                  <span style={{ fontSize: "10px" }}>
                    {getElapsedTime(new Date(publication.createDate!))}
                  </span>
                  {inputComent && (
                    <div
                      className="comentInputBox d-flex"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="InputComent"
                        style={{ width: "85%"}}
                      >
                        <input
                          type="text"
                          placeholder="Agrega un comentario..."
                          value={comentText}
                          onChange={(e) => setComentText(e.target.value)}
                          onKeyPress={handleKeyPress}
                          autoComplete="off"
                          disabled={spinnerComent}
                        />
                      </div>
                      {!spinnerComent && (
                        <div
                          className="text-center texto-publi"
                          style={{
                            width: "70px",
                            margin: "auto",
                            marginLeft: "10px",
                          }}
                          onClick={SaveComent}
                        >
                          <b style={{ color: "#1877f2", marginLeft: "-10px" }}>
                            Publicar
                          </b>
                        </div>
                      )}
                      {spinnerComent && (
                        <div
                          className="text-center texto-publi"
                          style={{ width: "10%"}}
                        >
                          <div
                            className="spinner-border text-primary coment-spinner"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div>
                    {seeComents &&
                      (allComents.length > 0 ? (
                        allComents.map((c: ComentModel, index: number) => (
                          <Coments
                            coment={c}
                            key={index}
                            handleComentChange={handleComentChange}
                            onDelete={() => deleteComment(index)}
                          />
                        ))
                      ) : (
                        <div className="col-md-12 text-center texto-publi">
                          {getComents()}
                          <div
                            className="spinner-border text-primary coment-spinner"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {isModalOptionsVisible && (
                <ModalOptions
                  setModalVisible={setModalOptionsVisible}
                  isModalVisible={isModalOptionsVisible}
                  options={options}
                />
              )}
            </div>
            <br />
            <br />
          </div>
        )}
      </>
    );
  }
);
export default PublicationsDetails;

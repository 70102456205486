import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Validate from "../Services/Validate/ValidateService";
import IconLogo from "../../components/assets/img/icon.png";
import Logo from "../../components/assets/img/Logo.png";
import FooterBlog from "../Modules/FooterBlog/FooterBlog";
import ChargerSlice from "../Modules/ChargerSlice/ChargerSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ForgotPassPage = () => {
  const navigate = useNavigate();
  const [checking, setChecking] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [errorIsActive, setErrorIsActive] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [validateEmail, setValidateEmail] = useState<boolean>(false);
  const [signsteps, setSignSteps] = useState<number>(0);

  const [digitoOne, setDigitoOne] = useState<number>(0);
  const [digitoTwo, setDigitoTwo] = useState<number>(0);
  const [digitoTree, setDigitoTree] = useState<number>(0);
  const [digitoFour, setDigitoFour] = useState<number>(0);
  const [digitoFive, setDigitoFive] = useState<number>(0);
  const [digitoSix, setDigitoSix] = useState<number>(0);

  const ValidateEmail = () => {
    if (email !== undefined && email !== "") {
      Validate.validateEmail(email.toLowerCase())
        .then((res: any) => {
          if (res.data) {
            setValidateEmail(false);
            setError("Email no registrado");
            setErrorIsActive(true);
          } else {
            setValidateEmail(true);
            setErrorIsActive(false);
          }
        })
        .catch((res: any) => {});
    } else {
      setValidateEmail(false);
    }
  };

  return (
    <div className="cont">
      {!checking && (
        <>
          <div className="col-md-4 m-auto">
            <br />
            <br />
            <br />
            <br />
            <Link to="/Login">
              <div className="logo_login">
                <img src={IconLogo} />
              </div>
              <div className="logo_login2">
                <img src={Logo} />
              </div>
            </Link>
            <br />
            {signsteps === 0 && (
              <>
                <h5>Recuperar cuenta</h5>
                <span>Has olvidado la contraseña de tu cuenta?</span>
                <br />
                <span>
                  No te preocupes, pasa en las mejores familias. Ingresa tu
                  correo electrónico y te enviaremos un enlace para restablecer
                  tu contraseña.
                </span>
                <br />
                <br />
                <div className="input-div-signup col-sm-10 m-auto InputDiv">
                  <input
                    className="form-control input-signup"
                    type="email"
                    placeholder="Email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onBlur={ValidateEmail}
                    value={email}
                  />
                  <FontAwesomeIcon
                    className="IconValidation"
                    style={{ color: validateEmail ? "green" : "#e75e69" }}
                    icon={faCheckCircle}
                  />
                </div>
                <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                  {errorIsActive && (
                    <span className="ErrorLegens">{error}</span>
                  )}
                </div>
                <button
                  className="form-control btn btn-primary"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                  onClick={() => {
                    if (email === undefined || email === "") {
                      setError("Debes completar tu email");
                      setErrorIsActive(true);
                      setValidateEmail(false);
                    } else {
                      if (
                        /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
                          email
                        )
                      ) {
                        ValidateEmail();
                        if (validateEmail) {
                          setSignSteps(signsteps + 1);
                          setErrorIsActive(false);
                        }
                      } else {
                        setError("Email no valido ");
                        setErrorIsActive(true);
                        setValidateEmail(false);
                      }
                    }
                  }}
                >
                  Siguiente
                </button>

                <div className="links-log">
                  <span>Tengo una cuenta </span>
                  <Link to="/login">
                    <span>Iniciar Sesion</span>
                  </Link>
                  <br />
                </div>
              </>
            )}
            {signsteps === 1 && (
              <>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", marginTop: "-3px" }}>
                    <FontAwesomeIcon
                      className="searchIcon"
                      style={{ cursor: "pointer" }}
                      icon={faArrowLeft}
                      onClick={() => {
                        setSignSteps(signsteps - 1);
                        setErrorIsActive(false);
                        // setNewPassword(undefined);
                        // setReplyNewpPassword(undefined);
                      }}
                    />
                  </div>
                  <div>
                    <h5>Codigo de verificacion</h5>
                  </div>
                </div>
                <span>
                  Te hemos enviado un código de 6 dígitos a tu correo
                  electrónico. Por favor, ingrésalo para restablecer tu cuenta.
                </span>
                <div
                  className="input-div-signup col-sm-10 m-auto mb-1 mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // border: "1px solid red",
                  }}
                >
                  <input
                    style={{ width: "10%", height: "50px" }}
                    className="form-control"
                    type="text"
                    // placeholder="Nombre"
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    style={{ width: "10%", height: "50px" }}
                    className="form-control"
                    type="text"
                    // placeholder="Nombre"
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    style={{ width: "10%", height: "50px" }}
                    className="form-control"
                    type="text"
                    // placeholder="Nombre"
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    style={{ width: "10%", height: "50px" }}
                    className="form-control"
                    type="text"
                    // placeholder="Nombre"
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    style={{ width: "10%", height: "50px" }}
                    className="form-control"
                    type="text"
                    // placeholder="Nombre"
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    style={{ width: "10%", height: "50px" }}
                    className="form-control"
                    type="text"
                    // placeholder="Nombre"
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                  {errorIsActive && (
                    <span className="ErrorLegens">{error}</span>
                  )}
                </div>
                <button
                  className="form-control btn btn-primary"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                  onClick={() => {
                    // if (name === undefined || name === "") {
                    //   setError("Debes completar tu nombre");
                    //   setErrorIsActive(true);
                    // } else {
                    //   setSignSteps(signsteps + 1);
                    //   setErrorIsActive(false);
                    // }
                  }}
                >
                  Verificar
                </button>
              </>
            )}
          </div>
          <FooterBlog />
        </>
      )}
      {checking && <ChargerSlice />}
    </div>
  );
};

export default ForgotPassPage;

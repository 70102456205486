import React from 'react'
import AycoroLogo from '../../assets/img/icon.png'

const Aycoro = () => {
    return (
        <>
            <br />
            <br />
            <div className="col-md-8 config-container">
                <h3>Aycoro</h3>
                <hr />
                <div className="col-md-12 d-flex justify-content-between">
                    <div className="col-md-5">
                        <p>
                            Es la primera red social de la Republica Dominicana, creada por jovenes dominicanos en el 2022, es una aplicación web multiplataforma,, que permite compartir publicaciones de imágenes y texto, en un muro donde tus seguidores pueden interactuar con dichas publicaciones a través de comentarios y likes, además de tener un chat en el que puedes intercambiar mensajes entre usuarios y compartir historias diarias de 24 horas de duración.
                            <br />
                            <br />
                            Dicha red social usa la modalidad de seguidores, en la cual un usuario puede seguir otro usuario para tener acceso a sus publicaciones e historias pudiendo otro usuario seguirlo también.
                        </p>
                    </div>
                    <div className="col-md-5">
                        <img className="col-md-12" src={AycoroLogo} alt="" />
                        <b className="name">Logo de Aycoro</b>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aycoro
import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleUp,
  faCamera,
  faClose,
  faMicrophone,
  faPlay,
  faStopCircle,
} from "@fortawesome/free-solid-svg-icons";
import Auth from "../../../../../Services/Auth/AuthService";
import Chat_S from "../../../../../Services/Chat/ChatService";
import { MessageParams } from "../../../../../Models/Message/MessageParams";
import { UserPerfilModel } from "../../../../../Models/User/UserPerfilModel";
import Data from "../../../../../Services/Data/Data";
import imageCompression from "browser-image-compression";
import MediaData from "../../../../../Services/MediaData/MediaDataService";
import { MediaDataModel } from "../../../../../Models/MediaData/MediaDataModel";
import { MediaType, MessageType } from "../../../../../constants/Types";
import "./SendMessageForm.css";

interface ISendMessageForm {
  userData: UserPerfilModel | undefined;
  idChat: string | undefined;
}

const SendMessageForm = ({ userData, idChat }: ISendMessageForm) => {
  const [message, setMessage] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audioMediaData, setAudioMediaData] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const [seconds, setSeconds] = useState<number>(0);
  const recordingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const startRecording = async (): Promise<void> => {
    try {
      setSeconds(0); // Reinicia el contador a 0
      recordingIntervalRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000); // Incrementa cada segundo

      const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event: BlobEvent): void => {
        audioChunks.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = async (): Promise<void> => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });
        setAudioUrl(URL.createObjectURL(audioBlob));
        audioChunks.current = [];
        const audioBase64 = await convertAudioToBase64(audioBlob);
        setAudioMediaData(audioBase64);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const handleSendAudio = async () => {
    if (audioUrl) {
      if (audioMediaData) {
        const mediaData: MediaDataModel = {
          Id: "",
          Value: audioMediaData,
          Type: MediaType.AUDIO,
        };

        await saveMediaData(mediaData, (seconds * 1000).toString());
      }
    }
  };

  const convertAudioToBase64 = (audioBlob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(audioBlob);
    });
  };

  const stopRecording = (): void => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (recordingIntervalRef.current) {
        clearInterval(recordingIntervalRef.current); // Detiene el temporizador
        recordingIntervalRef.current = null;
      }
    }
  };

  const deleteRecored = (): void => {
    setAudioUrl(null);
    setAudioMediaData(null);
  };

  const saveMessage = (m: MessageParams) => {
    Chat_S.Save(m).catch((e: any) => console.error(e));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  const handleSave = async () => {
    if (image) {
      const compressedImage = await compressImage(image);
      if (compressedImage) {
        const mediaData: MediaDataModel = {
          Id: "",
          Value: compressedImage,
          Type: MediaType.IMAGE,
        };
        await saveMediaData(mediaData, message);
      }
    } else {
      handleSend(MessageType.TEXT, message, undefined);
    }
  };

  const saveMediaData = async (media: MediaDataModel, message: string) => {
    const mediaResult = await MediaData.Create(media);
    handleSend(media.Type!, message, mediaResult.data.id);
  };

  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return await imageCompression.getDataUrlFromFile(compressedFile);
    } catch (error) {
      console.error("Error al comprimir la imagen:", error);
      return undefined;
    }
  };

  const resetForm = () => {
    setMessage("");
    setImage(null);
    setPreview(null);
    deleteRecored();
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      setImage(img);
      setPreview(URL.createObjectURL(img));
    }
  };

  const handleSend = (
    type: string,
    message: string | undefined,
    idMedia: string | undefined
  ) => {
    if (!idChat) return;
    const messageObj: MessageParams = {
      idChat: idChat,
      idUserSender: Auth._User?.user?.id!,
      idMediaDataProfileSender:
        Auth._User?.user?.perfilData?.idMediaDataProfile,
      idUserReceiver: userData?.user?.id,
      idMediaDataProfileReceiver:
        userData?.user?.perfilData?.idMediaDataProfile,
      messageValue: message,
      usernameReceiver: userData?.user?.username,
      verifyUserReceiver: userData?.user?.verify,
      usernameSender: Auth._User?.user?.username!,
      verifyUserSender: Auth._User?.user?.verify,
      nameReceiver: userData?.user?.name,
      nameSender: Auth._User?.user?.name,
      nameGroup: "",
      type: type,
      idMedia: idMedia,
      isDirect: true,
      createDate: `${new Date(Date.now())}`,
    };
    saveMessage(messageObj);
    resetForm();
  };

  return (
    <div style={{ height: "43px" }}>
      {preview && (
        <div style={{ display: "flex" }}>
          <div
            style={{
              marginTop: "-310px",
              marginLeft: "15px",
              cursor: "pointer",
            }}
            onClick={resetForm}
          >
            <FontAwesomeIcon icon={faClose} />
          </div>
          <div
            style={{
              width: "300px",
              height: "300px",
              marginTop: "-300px",
              position: "absolute",
              marginLeft: "30px",
            }}
          >
            <img
              style={{
                height: "100%",
                objectFit: "cover",
                objectPosition: "center center",
              }}
              src={preview}
              alt="Selected"
            />
          </div>
        </div>
      )}
      <div style={{ display: "flex" }}>
        {isRecording || audioUrl ? (
          isRecording ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "3px",
                  marginRight: "20px",
                }}
              >
                <span>00:{String(seconds).padStart(2, "0")}</span>
              </div>
              <div
                style={{
                  width: "83%",
                  height: "5px",
                  borderRadius: "20px",
                  backgroundColor: "purple",
                  marginTop: "15px",
                }}
              />
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "-2px",
                  cursor: "pointer",
                }}
                onClick={stopRecording}
              >
                <FontAwesomeIcon
                  style={{ fontSize: "35px", color: "red" }}
                  icon={faStopCircle}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "-2px",
                  cursor: "pointer",
                }}
                onClick={deleteRecored}
              >
                <FontAwesomeIcon
                  style={{ marginTop: "6px", fontSize: "25px", color: "red" }}
                  icon={faClose}
                />
              </div>
              {/* <div
                style={{
                  marginLeft: "20px",
                  marginTop: "-2px",
                  cursor: "pointer",
                }}
                // onClick={stopRecording}
              >
                <FontAwesomeIcon
                  style={{
                    // marginTop: "6px",
                    fontSize: "35px",
                    color: "purple",
                  }}
                  icon={faPlay}
                />
              </div>
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "3px",
                  marginRight: "20px",
                }}
              >
                <text>00:00</text>
              </div>
              <div
                style={{
                  width: "73%",
                  height: "5px",
                  borderRadius: "20px",
                  backgroundColor: "purple",
                  marginTop: "15px",
                }}
              /> */}
              {audioUrl && (
                <audio
                  style={{
                    width: "85%",
                    marginLeft: "20px",
                    marginTop: "-10px",
                    color: "purple",
                  }}
                  controls
                  src={audioUrl}
                />
              )}
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "-2px",
                  cursor: "pointer",
                }}
                onClick={handleSendAudio}
              >
                <FontAwesomeIcon
                  style={{
                    // marginTop: "6px",
                    fontSize: "35px",
                    color: "purple",
                  }}
                  icon={faArrowAltCircleUp}
                />
              </div>
            </div>
          )
        ) : (
          <>
            <input
              type="text"
              className="messageInput"
              placeholder="message..."
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              disabled={!idChat}
              onKeyPress={handleKeyPress}
              autoComplete="off"
              style={{ width: message ? "90%" : "86%" }}
            />
            {!message && !image && (
              <div
                style={{
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={() => imageInputRef.current?.click()}
              >
                <FontAwesomeIcon
                  style={{
                    marginTop: "6px",
                    fontSize: "25px",
                    color: "purple",
                  }}
                  icon={faCamera}
                />
              </div>
            )}
            {!message && !image && !isRecording && (
              <div
                style={{
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={startRecording}
              >
                <FontAwesomeIcon
                  style={{
                    marginTop: "6px",
                    fontSize: "25px",
                    color: "purple",
                  }}
                  icon={faMicrophone}
                />
              </div>
            )}
            {message && (
              // <button
              //   className="btn buttonsendmessage"

              //   disabled={!message && !image}
              //   style={{ backgroundColor: "purple" }}
              // >
              //   <b>Enviar</b>
              // </button>
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "-2px",
                  cursor: "pointer",
                }}
                onClick={handleSave}
              >
                <FontAwesomeIcon
                  style={{
                    // marginTop: "6px",
                    fontSize: "40px",
                    color: "purple",
                  }}
                  icon={faArrowAltCircleUp}
                />
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              ref={imageInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SendMessageForm;

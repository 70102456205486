import Http from "../Http/HttpClient";
import { IHistorySevice } from "./../../Interface/History/IHistoryService";

export class HistoryService implements IHistorySevice {
  async GetHistoryViewedList(id: string): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/History/GetHistoryViewedList/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async GetUserHistorysViewers(id: string, section: number): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/History/Viewers?IdHistory=${id}&section=${section}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async GetHistorysById(id: string): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`api/History/GetHistorysById/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async SetViewHistory(idhistory: string, iduser: string): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.post(
        `/api/History/ViewHistory?IdHistory=${idhistory}&idUser=${iduser}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async GetHistory(id: string): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`api/History/GetHistory/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async Create(idUser: string, MediaDataValue: string): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.post(`/api/History`, {
        IdUser: idUser,
        MediaDataValue: MediaDataValue,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }

  async GetHistoryList(idUser: string, section: number): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.get(`/api/History?idUser=${idUser}&section=${section}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }

  async Delete(id: string): Promise<any> {
    const result = await new Promise<any>((resolve, reject) => {
      Http.delete(`/api/History?idHistory=${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
}

const History = new HistoryService();
export default History;

import React from "react";
import { Navigate } from "react-router-dom";
import SignForm from "../Modules/SignForm/SignForm";
import Auth from "../Services/Auth/AuthService";

const SignUpPage = () => {
  return Auth._Authenticated ? <Navigate to="/"/> :  <SignForm />;
};

export default SignUpPage;

import { useNavigate } from "react-router-dom";
import './PerfilOptions.css'

const PerfilOptions = ({ setViewPPO, setArchivedView }: any) => {
  const navigate = useNavigate();
  return (
    <div className="perfilOptions">
      <div className="perfilOptionsContainer">
        <div
          className="perfilOptionsItems"
          onClick={() => navigate(`/settings`)}
        >
          <b>Configuracion</b>
        </div>
        <div
          className="perfilOptionsItems"
          onClick={() => {
            setArchivedView(true);
            setViewPPO(false);
          }}
        >
          <b>Archivados</b>
        </div>
        <div className="perfilOptionsItems">
          <b>Guardados</b>
        </div>
      </div>
    </div>
  );
};

export default PerfilOptions;

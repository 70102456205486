import React, { useEffect, useState } from 'react'

interface INotify {
    code: number
    objeto: string
}

const Notify = (code: number, objeto: string) => {
    const container = document.createElement('div');
    container.setAttribute('style', `
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #333;
        color: #fff;
        padding: 10px;
        text-align: center;
    `);

    let message: string;
    let color: string;

    switch (code) {
        case 100001:
            message = `${objeto} se actualizó exitosamente`;
            color = 'green';
            break;
        case 100007:
            message = `${objeto} fue activada correctamente`;
            color = 'green';
            break;
        case 100006:
            message = `${objeto} no pudo ser activada`;
            color = 'red';
            break;
        case 100009:
            message = `${objeto} fue desactivada`;
            color = 'orange';
            break;
        case 100019:
            message = `${objeto} no pudo ser desactivada`;
            color = 'red';
            break;
        case 100010:
            message = `${objeto} fue eliminada`;
            color = 'red';
            break;
        case 100110:
            message = `${objeto} no pudo ser eliminada`;
            color = 'orange';
            break;
        case 200001:
            message = `${objeto} actual no es correcta. Vuelve a intentarlo.`;
            color = 'red';
            break;
        case 999999:
            message = `Error actualizando ${objeto.toLowerCase()}`;
            color = 'red';
            break;
        default:
            message = `Código de notificación no reconocido: ${code} con ${objeto.toLowerCase()}`;
            color = 'black';
            break;
    }

    container.innerText = message;
    container.style.backgroundColor = color;
    document.body.appendChild(container);

    setTimeout(() => {
        document.body.removeChild(container);
    }, 2000);
};

export default Notify;
import React from "react";
import { Route } from "react-router-dom";
import { InboxPage } from "../Pages";
import CustomRoutes from "./CustomRoutes";

const InboxRoutes = () => {
  return (
    <CustomRoutes>
      <Route index element={<InboxPage />} />
      <Route path=":username" element={<InboxPage />} />
    </CustomRoutes>
  );
};

export default InboxRoutes;

import "./PhotoMosaic.css";
import { PostModel } from "../../../../Models/Post/PostModel";
import Mosaic from "./Mosaic";

interface IPhotoMosaic {
    posts: PostModel[];
    watchElement: Function
}

const PhotoMosaic = ({ posts, watchElement }: IPhotoMosaic) => {
  return (
    <div className="photo-mosaic col-md-10">
      {posts.map((e: PostModel, index: number) => {
        let size = "";
        if (index % 5 === 0) {
          size = "large";
        } else if (index % 7 === 0) {
          size = "medium";
        } else {
          size = "small";
        }
        return <Mosaic key={index} size={size} index={index} publication={e} watchElement={watchElement} />;
      })}
    </div>
  );
};

export default PhotoMosaic;

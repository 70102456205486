import {
  faChevronLeft,
  faChevronRight,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import { PostModel } from "../../../../Models/Post/PostModel";
import "./PerfilPublication.css";
import PerfilPublicationImage from "./PerfilPublicationImage";
import { useNavigate } from "react-router-dom";
import PublicationsDetails from "../PublicationsDetails";
import PublicationModalView from "../../../Modals/PublicationModalView";

interface IPerfilPublication {
  posts: PostModel[];
  setPost: any;
}

const PerfilPublication = ({ posts, setPost }: IPerfilPublication) => {
  const navigate = useNavigate();
  const [isVisiblePublicationModalView, setVisiblePublicationModalView] =
    useState<boolean>(false);
  const [indexPost, setindexPost] = useState<number>(0);

  // const Context = useContext(AppContext);

  // if (!Context) {
  //   // Manejar el caso cuando el contexto es nulo
  //   return null;
  // }
  // const { OnLoadPost } = Context;

  const scrollToElement = (index: number) => {
    setindexPost(index);
    setVisiblePublicationModalView(true);
  };

  const updatePost = (
    id: string,
    updates: Partial<
      Pick<PostModel, "description" | "status" | "likes" | "comments" | "liked">
    >
  ) => {
    const updatedPosts = posts!.map((post: PostModel) =>
      post.id === id ? { ...post, ...updates } : post
    );
    setPost(updatedPosts);
  };

  return (
    <>
      <div className="container container-post">
        {posts.map((p: PostModel, index: number) => (
          <PerfilPublicationImage
            key={index}
            index={index}
            scrollToElement={scrollToElement}
            publication={p}
          />
        ))}
      </div>

      {isVisiblePublicationModalView &&
        posts.length > 0 &&
        posts[indexPost] !== undefined && (
          <PublicationModalView
            setModalVisible={setVisiblePublicationModalView}
            isModalVisible={isVisiblePublicationModalView}
            index={indexPost}
            setIndex={setindexPost}
            publications={posts}
            setPublications={updatePost}
          />
        )}
    </>
  );
};

export default PerfilPublication;

import { useState } from "react";
import { UserModel } from "../../Models/User/UserModel";
import User from "../../Services/User/UserService";
import Validate from "../../Services/Validate/ValidateService";
import Select from "react-select";
import signUpConst from "../../Json/signUpConst.json";
import IconLogo from "../../assets/img/icon.png";
import Logo from '../../assets/img/Logo.png'
import { Link, useNavigate } from "react-router-dom";
import ChargerSlice from "../ChargerSlice/ChargerSlice";
import { faArrowLeft, faCheckCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SignForm.css";
import Data from "../../Services/Data/Data";
import FooterBlog from "../FooterBlog/FooterBlog";
import { UserCreateModel } from "../../Models/User/UserCreateModel";

const SignForm = () => {
  const navigate = useNavigate();
  const [checking, setChecking] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [errorIsActive, setErrorIsActive] = useState<boolean>(false);
  const [signsteps, setSignSteps] = useState<number>(0);

  const [name, setName] = useState<string | undefined>(undefined);
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [day, setDay] = useState<string | undefined>(undefined);
  const [month, setMonth] = useState<string | undefined>(undefined);
  const [monthLabel, setMonthLabel] = useState<string | undefined>(undefined);
  const [year, setYear] = useState<string | undefined>(undefined);
  const [gender, setGender] = useState<string | undefined>(undefined);
  const [genderLabel, setGenderLabel] = useState<string | undefined>(undefined);


  const [NewPassword, setNewPassword] = useState<string | undefined>(undefined);
  const [ReplyNewpPassword, setReplyNewpPassword] = useState<string | undefined>(undefined);

  const [VisibleNewPassword, setVisibleNewPassword] = useState<boolean>(false);
  const [VisibleReplyNewpPassword, setVisibleReplyNewpPassword] = useState<boolean>(false);


  const [validateEmail, setValidateEmail] = useState<boolean>(false);
  const [validateUsername, setValidateUsername] = useState<boolean>(false);
  const añoActual: number = new Date().getFullYear();
  const listaDeAños: { label: string }[] = Array.from({ length: añoActual - 1900 }, (_, index) => ({
    label: (1901 + index).toString(),
  })).reverse();

  const toggleVisibleNewPassword = () => {
    setVisibleNewPassword(!VisibleNewPassword);
  };

  const toggleVisibleReplyNewpPassword = () => {
    setVisibleReplyNewpPassword(!VisibleReplyNewpPassword);
  };

  const ValidateData = () => {
    if (day === undefined) {
      setError("Debes completar tu dia de nacimiento");
      setErrorIsActive(true);
    } else if (month === undefined) {
      setError("Debes completar tu mes de nacimiento");
      setErrorIsActive(true);
    } else if (year === undefined) {
      setError("Debes completar tu año de nacimiento");
      setErrorIsActive(true);
    } else {
      setChecking(true);
      let edad = getEdad(`${year}/${month}/${day} 00:00:00`);
      if (edad < 10) {
        nextStep();
        setChecking(false);
      } else {
        SaveUser();
      }
    }
  };
  const SaveUser = () => {
    setChecking(true);
    let data: UserCreateModel = {
      Id: "",
      Name: name,
      Username: username!.toLowerCase(),
      Phone: "",
      Email: email!.toLowerCase(),
      Password: NewPassword,
      Birthday: `${year}-${month}-${day}T00:00:00.000Z`,
      Gender: gender,
      CreateDate: Data._Today
    };

    User.Create(data)
      .then((res: any) => {
        if (res.status === 200) {
          navigate(`/`, { replace: true })
        } else if (res.status === 204) {
          setChecking(false);
          setError(res.data);
          setErrorIsActive(!errorIsActive);
        }
      })
      .catch((e: any) => {
        console.log(e);
        setChecking(false);
        setError(e);
        setErrorIsActive(!errorIsActive);
      });
  };

  function getEdad(dateString: string) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  const nextStep = () => {
    setSignSteps(signsteps + 1);
  };

  const ValidateEmail = () => {
    if (email !== undefined && email !== "") {
      Validate.validateEmail(email.toLowerCase())
        .then((res: any) => {
          if (res.data) {
            setValidateEmail(true);
            setErrorIsActive(false);
          } else {
            setValidateEmail(false);
            setError("Email no disponible");
            setErrorIsActive(true);
          }
        })
        .catch((res: any) => {
        });
    } else {
      setValidateUsername(false);
    }
  };

  const VerifyUsername = () => {
    if (username !== undefined && username !== "") {
      if (ValidateUsername(username)) {
        Validate.validateUsername(username.toLowerCase())
          .then((res: any) => {
            if (res.data) {
              setValidateUsername(true);
              setErrorIsActive(false);
            } else {
              setValidateUsername(false);
              setError("Usuario no disponible");
              setErrorIsActive(true);
            }
          })
          .catch((err: any) => {
            console.error(err);
          });
      }
    } else {
      setValidateUsername(false);
    }
  };

  const ValidateUsername = (username: string) => {
    if (username.length < 7 || username.length > 30) {
      setValidateUsername(false);
      setError("Usuario, maximo 30 caracteres, minimo 7 caracteres");
      setErrorIsActive(true);
      return false;
    }

    if (/^\d+$/.test(username)) {
      setValidateUsername(false);
      setError("El usuario no puede contener solo números");
      setErrorIsActive(true);
      return false;
    }

    if (!/^[a-zA-Z0-9_.-]+$/.test(username)) {
      setValidateUsername(false);
      setError("El usuario no puede contener caracteres especiales");
      setErrorIsActive(true);
      return false;
    }

    setValidateUsername(true);
    setErrorIsActive(false);
    return true;
  }

  const validarContrasena = (contrasena: string) => {
    if (contrasena.length < 6) {
      setError("La contraseña debe tener al menos 6 caracteres.");
      setErrorIsActive(true);
      return false;
    }

    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!$@%])[0-9a-zA-Z!$@%]+$/;
    if (!regex.test(contrasena)) {
      setError("La contraseña debe incluir números, letras y caracteres especiales (!$@%).");
      setErrorIsActive(true);
      return false;
    }
    setErrorIsActive(false);
    return true;
  }

  return (
    <div className="cont">
      {!checking && (
        <>
          <div className="col-md-4 m-auto">
            <br />
            <br />
            <br />
            <br />
            <Link to="/Login">
              <div className="logo_login">
                <img src={IconLogo} />
              </div>
              <div className="logo_login2">
                <img src={Logo} />
              </div>
            </Link>
            <br />
            {signsteps > 0 && (
              <FontAwesomeIcon
                className="searchIcon"
                style={{ cursor: "pointer" }}
                icon={faArrowLeft}
                onClick={() => {
                  setSignSteps(signsteps - 1);
                  setErrorIsActive(false);
                  setNewPassword(undefined);
                  setReplyNewpPassword(undefined);
                }}
              />
            )}
            {signsteps === 0 && (
              <>
                <h2>Registrate</h2>
                <span>
                  Es super facil. Solo tienes que completar un simple formulario y
                  podras compartir tus mejores momentos con tus amigos, familiares
                  y el mundo.
                </span>
                <br />
                <br />
                <h5>Nombre</h5>
                <span>
                  Usa el nombre por el cual te conozcan asi sera mas facil
                  encontrarte cuando te busquen.
                  <br />
                  Tu nombre debe tener un tamaño maximo de 50 caracteres.
                </span>
                <div className="input-div-signup col-sm-10 m-auto mb-1 mt-4">
                  <input
                    className="form-control input-signup"
                    type="text"
                    placeholder="Nombre"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                  {errorIsActive && <span className="ErrorLegens">{error}</span>}
                </div>
                <button
                  className="form-control btn btn-primary"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                  onClick={() => {

                    if (name === undefined || name === "") {
                      setError("Debes completar tu nombre");
                      setErrorIsActive(true);
                    } else {
                      setSignSteps(signsteps + 1);
                      setErrorIsActive(false);
                    }
                  }}
                >
                  Siguiente
                </button>
              </>
            )}

            {signsteps === 1 && (
              <>
                <h4>Usuario</h4>
                <span>
                  Tu usuario es otra de las formas en que las
                  personas pueden buscarte ademas de que es una llave unica
                  de acceso a tu cuenta.
                  <br />
                  Tu usuario debe tener un tamaño maximo de 30
                  caracteres y no se aceptan espacios ni caracteres
                  especiales.
                </span>
                <br />
                <br />
                <div className="input-div-signup col-sm-10 m-auto InputDiv">
                  <input
                    className="form-control input-signup"
                    type="text"
                    placeholder="Usuario"
                    onChange={(e) => {
                      if (/\s/.test(e.target.value)) {
                        setError("El usuario no puede contener espacios");
                        setErrorIsActive(true);
                      } else {
                        setUsername(e.target.value);
                      }
                    }}
                    onBlur={VerifyUsername}
                    value={username}
                  />
                  <FontAwesomeIcon
                    className="IconValidation"
                    style={{ color: validateUsername ? "green" : "#e75e69" }}
                    icon={faCheckCircle}
                  />
                </div>
                <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                  {errorIsActive && <span className="ErrorLegens">{error}</span>}
                </div>
                <button
                  className="form-control btn btn-primary"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                  onClick={() => {
                    if (username === undefined || username === "") {
                      setError("Debes completar tu usuario");
                      setErrorIsActive(true);
                    } else {
                      VerifyUsername();
                      if (validateUsername) {
                        setSignSteps(signsteps + 1);
                        setErrorIsActive(false);
                      }
                    }
                  }}
                >
                  Siguiente
                </button>
              </>
            )}

            {signsteps === 2 && (
              <>
                <h4>Email</h4>
                <div className="input-div-signup col-sm-10 m-auto InputDiv">
                  <input
                    className="form-control input-signup"
                    type="email"
                    placeholder="Email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onBlur={ValidateEmail}
                    value={email}
                  />
                  <FontAwesomeIcon
                    className="IconValidation"
                    style={{ color: validateEmail ? "green" : "#e75e69" }}
                    icon={faCheckCircle}
                  />
                </div>
                <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                  {errorIsActive && <span className="ErrorLegens">{error}</span>}
                </div>
                <button
                  className="form-control btn btn-primary"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                  onClick={() => {
                    if (email === undefined || email === "") {
                      setError("Debes completar tu email");
                      setErrorIsActive(true);
                      setValidateEmail(false);
                    } else {
                      if (
                        /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
                          email
                        )
                      ) {
                        ValidateEmail();
                        if (validateEmail) {
                          setSignSteps(signsteps + 1);
                          setErrorIsActive(false);
                        }
                      } else {
                        setError("Email no valido ");
                        setErrorIsActive(true);
                        setValidateEmail(false);
                      }
                    }
                  }}
                >
                  Siguiente
                </button>
              </>
            )}

            {signsteps === 3 && (
              <>
                <div className="col-sm-10 m-auto mb-1">
                  <h4>Contraseña</h4>
                  <span>
                    La contraseña debe tener al menos 6 caracteres e incluir una combinación de números,
                    letras y caracteres especiales (!$@%).
                  </span>
                  <br />
                  <br />
                </div>
                <div className="pass-input-div-signup col-sm-10 m-auto mb-1">
                  <input
                    className="form-control pass-input-signup"
                    type={VisibleNewPassword ? 'text' : 'password'}
                    placeholder="Nueva contraseña"
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={NewPassword}
                  />
                  {!VisibleNewPassword
                    ? <FontAwesomeIcon
                      className="icon-view-pass-signup"
                      icon={faEye} onClick={() => toggleVisibleNewPassword()} />
                    : <FontAwesomeIcon
                      className="icon-view-pass-signup"
                      icon={faEyeSlash} onClick={() => toggleVisibleNewPassword()} />
                  }
                </div>
                <div className="pass-input-div-signup col-sm-10 m-auto mb-1">
                  <input
                    className="form-control pass-input-signup"
                    type={VisibleReplyNewpPassword ? 'text' : 'password'}
                    placeholder="Repetir nueva contraseña"
                    onChange={(e) => setReplyNewpPassword(e.target.value)}
                    value={ReplyNewpPassword}
                  />
                  {!VisibleReplyNewpPassword
                    ? <FontAwesomeIcon
                      className="icon-view-pass-signup"
                      icon={faEye} onClick={() => toggleVisibleReplyNewpPassword()} />
                    : <FontAwesomeIcon
                      className="icon-view-pass-signup"
                      icon={faEyeSlash} onClick={() => toggleVisibleReplyNewpPassword()} />
                  }
                </div>
                <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                  {errorIsActive && (
                    <span className="ErrorLegens">{error}</span>
                  )}
                </div>
                <button
                  className="form-control btn btn-primary"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                  onClick={() => {

                    if (NewPassword === undefined || NewPassword === "") {
                      setError("Debes completar tu contraseña");
                      setErrorIsActive(true);
                    } else if (NewPassword != ReplyNewpPassword) {
                      setError("La contraseña nueva no coincide");
                      setErrorIsActive(true);

                    } else {
                      if (validarContrasena(NewPassword)) {
                        setErrorIsActive(false);
                        setSignSteps(signsteps + 1);
                      }
                    }
                  }}
                >
                  Siguiente
                </button>
              </>
            )}

            {signsteps === 4 && (
              <>
                <br />
                <div className="col-sm-10 m-auto mb-1">
                  <span>Fecha de Nacimiento</span>
                </div>
                <div className="col-sm-10 m-auto mb-1 d-flex justify-content-between">
                  <div className="col-sm-4" style={{ paddingRight: "1px" }}>
                    <Select
                      options={signUpConst.days}
                      onChange={(e: any) => setDay(e.label)}
                      placeholder={day ? day : "Dia"}
                    />
                  </div>
                  <div className="col-sm-4" style={{ paddingRight: "1px" }}>
                    <Select
                      options={signUpConst.months}
                      onChange={(e: any) => {
                        setMonth(e.value);
                        setMonthLabel(e.label);
                      }}
                      placeholder={monthLabel ? monthLabel : "Mes"}
                    />
                  </div>
                  <div className="col-sm-4" style={{ paddingRight: "1px" }}>
                    <Select
                      options={listaDeAños}
                      onChange={(e: any) => setYear(e.label)}
                      placeholder={year ? year : "Año"}
                    />
                  </div>
                </div>
                <div className="col-sm-10 m-auto mb-1">
                  <Select
                    options={signUpConst.gender}
                    onChange={(e: any) => {
                      setGender(e.value);
                      setGenderLabel(e.label);
                    }}
                    placeholder={genderLabel ? genderLabel : "Genero"}
                  />
                </div>
                <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                  {errorIsActive && <span className="ErrorLegens">{error}</span>}
                </div>
                <button
                  className="form-control btn-success"
                  onClick={ValidateData}
                >
                  Registrate
                </button>
              </>
            )}
            {signsteps === 5 && (
              <>
                <br />
                <div className="col-sm-10 m-auto mb-1">
                  <p>
                    Hemos detectado que la edad de esta cuenta esta por debajo del limite de edad permitido, 
                    la creacion de esta cuenta solo esta permitida con
                    la condicion de que esta sea monitorada por padres o tutores,
                    aycoro no se hace responsable por desacato a la siguiente
                    condicion click en aceptar si estas de acuerdo con la
                    siguiente condicion.
                  </p>
                  <br />
                </div>
                <div className="col-sm-11 m-auto mb-1 d-flex justify-content-between">
                  <div className="col-md-5">
                    <button
                      className="form-control btn-danger"
                      onClick={() => {
                        setSignSteps(signsteps - 1);
                      }}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-md-5">
                    <button
                      className="form-control btn-success"
                      onClick={SaveUser}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </>
            )}
            {signsteps < 4 && (
              <div className="links-log">
                <span>Tengo una cuenta </span>
                <Link to="/login">
                  <span>Iniciar Sesion</span>
                </Link>
                <br />
              </div>
            )}
          </div>
          <FooterBlog />
        </>
      )}
      {checking && <ChargerSlice />}
    </div>
  );
};

export default SignForm;

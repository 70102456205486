import { useContext, useEffect } from "react";
import UserPerfil from "../Modules/UserPerfil/UserPefil";
import Footer from "../Modules/Footer/Footer";
import ChargerSlice from "../Modules/ChargerSlice/ChargerSlice";
import { Link, useParams } from "react-router-dom";
import Auth from "../Services/Auth/AuthService";
import PerfilPublication from "../Modules/Publications/Publication/PerfilPublication/PerfilPublication";
import { AppContext } from "../context/AppContext";
import { PerfilPostCharger } from "../Modules/Charger/PerfilPostCharger/PerfilPostCharger";

const PerfilPage = () => {
  const { username } = useParams();

  const {
    searchVisible,
    buttonsVisible,
    setButtonsVisible_F,
    ConcatMyHistorys,
    setPhoto,
    setOnLoadPhoto,
    setFooterViewer_F,
    footerViewer,
    perfilPost,
    setPerfilPost,
    perfiluser,
    getPerfilPost,
    getPerfilDta,
    cantOfFollow,
    setCantOfFollow,
    cantOfFollowings,
    setCantOfFollowings,
    isActiveSpinner,
    isPostCharging,
    isfollow,
    setIsFollow,
    isOnline,
  } = useContext(AppContext) ?? {};

  useEffect(() => {
    if (username) {
      getPerfilPost!(username);
      getPerfilDta!(username);
    }
  }, [username]);

  useEffect(() => {
    if (isOnline) {
      isOnline(Auth._User?.user?.id!, Auth._User?.user?.username!);
    }
  }, [isOnline]);

  if ((!perfiluser || (perfiluser.user!.id === "")) && !isActiveSpinner) {
    return (
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <h3>Usuario no encontrado</h3>
        <b>
          Este usuario no existe o ha sido eliminado.{" "}
          <Link to="/">
            <span>Volver a Aycoro.</span>
          </Link>
        </b>
      </div>
    );
  }

  if (isActiveSpinner) return <ChargerSlice />;

  if (searchVisible) return null;

  return (
    <>
      <UserPerfil
        userData={perfiluser}
        cantOfFollow={cantOfFollow!}
        setCantOfFollow={setCantOfFollow!}
        cantOfFollowings={cantOfFollowings!}
        setCantOfFollowings={setCantOfFollowings!}
        isActiveSpinner={isActiveSpinner!}
        isfollow={isfollow!}
        setIsFollow={setIsFollow!}
      />
      <Footer
        buttonVisible={buttonsVisible!}
        setButtonsVisible={setButtonsVisible_F!}
        setPhotos={setPhoto!}
        setOnLoadPhoto={setOnLoadPhoto!}
        setFooterViewer={setFooterViewer_F!}
        footerViewer={footerViewer!}
        setMyHistorys={ConcatMyHistorys!}
      />
      <PerfilPublication posts={perfilPost!} setPost={setPerfilPost} />
      {isPostCharging && <PerfilPostCharger />}
    </>
  );
};

export default PerfilPage;

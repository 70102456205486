import React from "react";
import { Route } from "react-router-dom";
import AppLayout from "../Layout/AppLayout";
import {
  ExplorerPage,
  HomePage,
  LoginPage,
  PerfilPage,
  PublicationPage,
  SettingsPage,
  SignUpPage,
} from "../Pages";
import ComunityRoutes from "./ComunityRoutes";
import CustomRoutes from "./CustomRoutes";
import InboxRoutes from "./InboxRoutes";
import { PrivateRoute } from "./PrivateRoute";

const DashboardRoutes = () => {
  return (
    <CustomRoutes>
      <Route element={<PrivateRoute />}>
        <Route element={<AppLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/:username" element={<PerfilPage />} />
          <Route path="/explorer" element={<ExplorerPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/inbox/*" element={<InboxRoutes />} />
          <Route path="/publication/:id" element={<PublicationPage />} />
        </Route>
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/comunity/*" element={<ComunityRoutes />} />
    </CustomRoutes>
  );
};

export default DashboardRoutes;

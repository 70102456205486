import { useMemo, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LocalizationContext } from '../context/LocalizationContext';
import Auth from '../Services/Auth/AuthService';
import DashboardRoutes from './DashboardRoutes';
import "../../App.css";
import useLocalStorage from 'use-local-storage';
import { ThemeContext } from '../context/ThemeContext';

export default function RouterManager() {
  const [localization, setLocalization] = useState<string>(Auth.GetToken()!);
  const valueLocalization = useMemo(
    () => ({ localization, setLocalization }),
    [localization, setLocalization]
  );

  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );

  const setThemes = (theme: string) => {
    setTheme(theme)
  }

  return (
    <LocalizationContext.Provider value={valueLocalization}>
      <ThemeContext.Provider value={{ theme, setThemes }}>
        <div className="App"
          data-theme={theme}
          id="app_contain"
        >
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<DashboardRoutes />} />
                <Route path="/*" element={<DashboardRoutes />} />
              </Route>
            </Routes>
          </BrowserRouter>
          <div id='publications-end'></div>
        </div>
      </ThemeContext.Provider>
    </LocalizationContext.Provider>
  );
}

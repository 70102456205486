import React, { useContext, useEffect } from "react";
import InboxBox from "../Modules/Inbox/InboxBox";
import { AppContext } from "../context/AppContext";
import Auth from "../Services/Auth/AuthService";

const InboxPage = () => {
  const {
    searchVisible,
    usersConnecting,
    messages,
    lastMessageState,
    setMessages_F,
    isOnline,
  } = useContext(AppContext) || {};

  useEffect(() => {
    document.title = `${process.env.REACT_APP_TITTLE_BASE} • Bandeja de Entrada`;
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, []);

  useEffect(() => {
    if (Auth._User?.user?.id && Auth._User?.user?.username) {
      isOnline!(Auth._User.user.id, Auth._User.user.username);
    }
  }, [isOnline]);

  if (searchVisible) return null;

  return (
    <InboxBox
      usersConnecting={usersConnecting}
      messages={messages}
      LastMessage={lastMessageState!}
      setMessages={setMessages_F!}
    />
  );
};

export default InboxPage;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./FooterBlog.css";

const FooterBlog = () => {
  const navigate = useNavigate();
  const handleRedirect = (path: string) => {
    if (window.location.pathname.substring(0, 10) !== "/comunity/") {
      window.open(`${window.location.origin}/comunity${path}`, "_blank");
    } else {
      navigate(`/comunity${path}`, { replace: true });
    }
  };
  return (
    <>
      <div className="footer_blog">
        <div className="contain_option_blog">
          <a href="/comunity/aycoro">Aycoro</a>
          {/* onClick={() => handleRedirect("/aycoro")} */}
          <a href="/comunity/ayuda">Ayuda</a>
          <a href="/comunity/contacto">Contactos</a>
          <a href="/comunity/terminos">Términos y condiciones</a>
        </div>
        <span className="version">©Aycoro {new Date().getFullYear()}</span>
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default FooterBlog;
